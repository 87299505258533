import React from "react";

export const Splitter = ({
    width1,
    width2,
    children,
}: {
    width1?: number;
    width2?: number;
    children: any;
}) => {
    let finalW1 = 49;
    let finalW2 = 49;
    if (width1) {
        finalW1 = width1;
    }
    if (width2) {
        finalW2 = width2;
    }
    const gap = Math.max(0, 100 - (finalW1 + finalW2));

    const style = {
        display: "grid",
        gridTemplateRows: "1fr",
        gridTemplateColumns: `${finalW1}% ${finalW2}%`,
        gridGap: `${gap}%`,
    };
    return <div style={style}>{children}</div>;
};

export const SplitterButtons = ({
    width1,
    width2,
    children,
}: {
    width1?: number;
    width2?: number;
    children: any;
}) => {
    let finalW1 = 49;
    let finalW2 = 49;
    if (width1) {
        finalW1 = width1;
    }
    if (width2) {
        finalW2 = width2;
    }
    const gap = Math.max(0, 100 - (finalW1 + finalW2));

    const style = {
        display: "grid",
        gridTemplateRows: "1fr",
        gridTemplateColumns: `${finalW1}% ${finalW2}%`,
        gridGap: `${gap}%`,
        margin: `5px`,
    };
    return <div style={style}>{children}</div>;
};

export const SplitterV = (props) => {
    return <div className="splitterV">{props.children}</div>;
};

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React from "react";
import {Button, Spinner} from "reactstrap";

type Props = {
    onClick: () => void;
    disabled: boolean;
    pending: boolean;
};

export function NoButton({onClick, disabled, pending}: Props) {
    return (
        <Button
            id="ignoreButton"
            type="button"
            color="secondary"
            onClick={onClick}
            disabled={disabled}
        >
            <span>{pending && <Spinner size="sm" color="light" />} Non merci</span>
        </Button>
    );
}

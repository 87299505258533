import axios from "axios";
import {convertDistance, getDistance as getGeolibDistance} from "geolib";
import {GPSPosition, ICustomer, IEvent, IGeocode, IPlatformUser} from "../types";
import {numberService} from "./numberService";
import {Numberable} from "./types";

export const SCORE_LIMIT = 0.6;

export const getDistance = (
    autoDistance: Numberable | null,
    manualDistance: Numberable | null | undefined
): number => {
    let result = autoDistance !== null ? autoDistance : 0;
    if (manualDistance || manualDistance === 0) {
        result = manualDistance;
    }
    return Math.max(0, numberService.floatValue(result)); // avoid negative result
};

export const setPlatformUserGeocode = (user: IPlatformUser, geocode: IGeocode): void => {
    if (geocode) {
        user.long = geocode.longitude;
        user.lat = geocode.latitude;
        user.geocodeScore = geocode.score;
    } else {
        user.long = undefined;
        user.lat = undefined;
        user.geocodeScore = undefined;
    }
};

export const setCustomerGeocode = (customer: ICustomer, geocode: IGeocode): void => {
    if (geocode) {
        customer.serviceLong = geocode.longitude;
        customer.serviceLat = geocode.latitude;
        customer.serviceGeocodeScore = geocode.score;
    } else {
        customer.serviceLong = undefined;
        customer.serviceLat = undefined;
        customer.serviceGeocodeScore = undefined;
    }
};

export const computeZoneDistance = (platformUser: IPlatformUser, customer: ICustomer): number => {
    let result = 0;
    const userPosition = getPosition(platformUser);
    const customerPosition = getPosition(customer);
    if (userPosition && customerPosition) {
        const distanceInMeters = getGeolibDistance(userPosition, customerPosition);
        const distanceInKMeters = convertDistance(distanceInMeters, "km");
        result = numberService.normalize(distanceInKMeters);
    }
    return result;
};

const getPosition = (object: IPlatformUser | ICustomer): GPSPosition | null => {
    let result: GPSPosition | null = null;
    if ("long" in object && "lat" in object && object.long && object.lat) {
        result = {
            longitude: parseFloat(object.long),
            latitude: parseFloat(object.lat),
        };
    } else if (
        "serviceLong" in object &&
        "serviceLat" in object &&
        object.serviceLong &&
        object.serviceLat
    ) {
        result = {
            longitude: parseFloat(object.serviceLong),
            latitude: parseFloat(object.serviceLat),
        };
    }
    return result;
};

export const computeDistance = async (
    coordinates: [string, string][],
    openRouteKey: string
): Promise<number | undefined> => {
    const openrouteData = {
        preference: "fastest",
        language: "fr",
        units: "km",
        instructions: false,
        elevation: false,
        coordinates,
    };
    const geometrySimplify = "geometry_simplify";
    openrouteData[geometrySimplify] = true;

    try {
        const {data} = await axios.post(
            "https://api.openrouteservice.org/v2/directions/driving-car/geojson",
            openrouteData,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `${openRouteKey}`,
                },
            }
        );
        const {
            features: [
                {
                    properties: {
                        summary: {distance},
                    },
                },
            ],
        } = data;
        if (distance && distance > 0) {
            return numberService.normalize(distance);
        }
    } catch (e) {
        console.error(e.message);
    }
};

export const computeEventCoordinates = (
    userLong: string,
    userLat: string,
    events: IEvent[]
): [string, string][] => {
    const userCoordinate: [string, string] = [userLong, userLat];
    const coordinates: [string, string][] = [];
    let prevCoordinate = userCoordinate;
    for (const event of events) {
        if (event.customer) {
            const {serviceLong, manualServiceLong, serviceLat, manualServiceLat} = event.customer;
            const eventLong = manualServiceLong ? manualServiceLong : serviceLong;
            const eventLat = manualServiceLat ? manualServiceLat : serviceLat;
            if (eventLong && eventLat) {
                const nextCoordinate: [string, string] = [eventLong, eventLat];
                const [prevLong, prevLat] = prevCoordinate;
                if (eventLong !== prevLong || eventLat !== prevLat) {
                    coordinates.push(nextCoordinate);
                    prevCoordinate = nextCoordinate;
                } // else ignore
            }
        }
    }

    let finalCoordinates: [string, string][] = [];
    if (coordinates.length > 0) {
        // wrap userCoordinate
        const firstCoordinate = coordinates[0];
        const lastCoordinate = coordinates[coordinates.length - 1];
        const [firstLong, firstLat] = firstCoordinate;
        const [lastLong, lastLat] = lastCoordinate;
        finalCoordinates = [...coordinates];

        if (userLong !== firstLong || userLat !== firstLat) {
            // Add user coordinate in first only if required
            finalCoordinates = [userCoordinate, ...finalCoordinates];
        }
        if (userLong !== lastLong || userLat !== lastLat) {
            // Add user coordinate in last only if required
            finalCoordinates = [...finalCoordinates, userCoordinate];
        }
    }
    return finalCoordinates;
};

import React from "react";

export function Step0() {
    return (
        <>
            <h2 style={{marginTop: "8px", marginBottom: "16px"}} className="title-custom-style">
                {`Suivez le guide !`}
            </h2>

            <p
                style={{marginTop: "64px"}}
            >{`Nous allons visiter ensemble les différentes pages de MyTour !`}</p>
        </>
    );
}

import React from "react";
import {Button, Spinner} from "reactstrap";

type Props = {
    onClick: () => void;
    disabled: boolean;
    pending: boolean;
};

export function YesButton({onClick, disabled, pending}: Props) {
    return (
        <Button
            id="nextButton"
            type="button"
            color="success"
            onClick={onClick}
            disabled={disabled}
        >
            <span>{pending && <Spinner size="sm" color="light" />} Oui</span>
        </Button>
    );
}

import dayjs from "dayjs";
import {OrderKind} from "../../module/entities/payment-order/OrderKind";
import {IPlatformUser} from "../types";

function endOfTrial(
    createdDate: Date | null | undefined,
    lastPayment: Date | null | undefined,
    now: Date
): boolean {
    let result = false;
    if (createdDate) {
        if (lastPayment === null || lastPayment === undefined) {
            const nextPaymentDate = dayjs(createdDate)
                .add(1, "month")
                .endOf("day");
            if (dayjs(now).isAfter(nextPaymentDate)) {
                result = true;
            }
        } else {
            result = true;
        }
    }
    return result;
}

function shouldTriggerRecurringPayment(
    lastPayment: Date | null | undefined,
    lastOrderKind: OrderKind,
    now: Date
): boolean {
    let result = false;
    if (lastPayment) {
        // We can compute the should pay only when at least one payment has been performed.
        const nextPaymentDate = dayjs(lastPayment).add(
            1,
            lastOrderKind === OrderKind.MONTHLY ? "month" : "year"
        );
        if (dayjs(now).isSame(nextPaymentDate, "day") || dayjs(now).isAfter(nextPaymentDate)) {
            result = true;
        }
    }
    return result;
}

function isDiscount(platformUser: IPlatformUser) {
    const id = platformUser?.id;
    if (!id) {
        return false;
    }
    return false;
    /**
     * edgarine.pls@gmail.com
     * podologueequin29@gmail.com
     * melanie.equipodo@gmail.com
     * emeline.podologie.equine@gmail.com
     * emelinerbl@gmail.com
     */
    /*
    return [
        "bf8dfa5f-38ef-4fbd-a5f6-81f9b43bff70",
        "bc91bc6c-c819-4a0d-b215-833139b94b6a",
        "86775a98-ef3e-42d0-92ad-c55d7a769469",
        "d1c36f5b-7663-45ca-b7d5-41017ff8ee5f",
        "a2497168-e72b-431c-9719-cc46517e5ca3",
    ].includes(id);*/
}

export const paymentService = {
    endOfTrial,
    shouldTriggerRecurringPayment,
    isDiscount,
};

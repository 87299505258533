import i18n from "config/i18n";
import {TOptions} from "i18next";
import {isPromise} from "react-jhipster";
import {toast} from "react-toastify";
const {t: translate} = i18n;
const addErrorAlert = (
    message,
    key?,
    options?:
        | string
        | TOptions<{
              param: string;
          }>
) => {
    key = key ? key : message;
    const translated = translate(key, options);
    toast.error(translated);
};
export default () => (next) => (action) => {
    // If not a promise, continue on
    if (!isPromise(action.payload)) {
        return next(action);
    }

    /**
     *
     * The notification middleware serves to dispatch the initial pending promise to
     * the promise middleware, but adds a `then` and `catch.
     */
    return next(action)
        .then((response) => {
            if (action.meta && action.meta.successMessage) {
                toast.success(action.meta.successMessage);
            } else if (
                response &&
                response.action &&
                response.action.payload &&
                response.action.payload.headers
            ) {
                const headers = response.action.payload.headers;
                let alert: string | null = null;
                let alertParams: string | null = null;
                Object.entries<string>(headers).forEach(([k, v]) => {
                    if (k.toLowerCase().endsWith("app-alert")) {
                        alert = v;
                    } else if (k.toLowerCase().endsWith("app-params")) {
                        alertParams = decodeURIComponent(v.replace(/\+/g, " "));
                    }
                });
                if (alert) {
                    const alertParam = alertParams;
                    const translated = translate(alert, {param: alertParam});
                    toast.success(translated);
                }
            }
            return Promise.resolve(response);
        })
        .catch((error) => {
            if (action.meta && action.meta.errorMessage) {
                toast.error(action.meta.errorMessage);
            } else if (
                error &&
                error.config &&
                error.config.url === "api/authenticate" &&
                error.config.method === "post"
            ) {
                /* eslint-disable no-console */
                console.log("Authentication Error.");
            } else if (
                error &&
                error.config &&
                error.config.url.startsWith("api/invoice-file") &&
                error.config.method === "get"
            ) {
                // ignore
            } else if (error && error.response) {
                const response = error.response;
                const data = response.data;
                if (
                    !(
                        response.status === 401 &&
                        (error.message === "" ||
                            (data && data.path && data.path.includes("/api/account")))
                    )
                ) {
                    let i;
                    switch (response.status) {
                        // connection refused, server not reachable
                        case 0:
                            addErrorAlert("Server not reachable", "error.server.not.reachable");
                            break;

                        case 400: {
                            const headers = Object.entries<string>(response.headers);
                            let errorHeader: string | null = null;
                            let entityKey: string | null = null;
                            headers.forEach(([k, v]) => {
                                if (k.toLowerCase().endsWith("app-error")) {
                                    errorHeader = v;
                                } else if (k.toLowerCase().endsWith("app-params")) {
                                    entityKey = v;
                                }
                            });
                            if (errorHeader) {
                                const entityName = translate("global.menu.entities." + entityKey);
                                addErrorAlert(errorHeader, errorHeader, {param: entityName});
                            } else if (data !== "" && data.fieldErrors) {
                                const fieldErrors = data.fieldErrors;
                                for (i = 0; i < fieldErrors.length; i++) {
                                    const fieldError = fieldErrors[i];
                                    if (
                                        ["Min", "Max", "DecimalMin", "DecimalMax"].includes(
                                            fieldError.message
                                        )
                                    ) {
                                        fieldError.message = "Size";
                                    }
                                    // convert 'something[14].other[4].id' to 'something[].other[].id' so translations can be written to it
                                    const convertedField = fieldError.field.replace(
                                        /\[\d*\]/g,
                                        "[]"
                                    );
                                    const fieldName = translate(
                                        `myTourApp.${fieldError.objectName}.${convertedField}`
                                    );
                                    addErrorAlert(
                                        `Error on field "${fieldName}"`,
                                        `error.${fieldError.message}`,
                                        {param: fieldName}
                                    );
                                }
                            } else if (data !== "" && data.message) {
                                addErrorAlert(data.message, data.message, data.params);
                            } else {
                                addErrorAlert(data);
                            }
                            break;
                        }
                        case 404:
                            if (action.type === "event/FETCH_EVENT") {
                                // ignore
                            } else {
                                addErrorAlert("Not found", "error.url.not.found");
                            }
                            break;

                        default:
                            if (data !== "" && data.message) {
                                if (response.status === 401) {
                                    // ignore
                                } else {
                                    addErrorAlert(data.message);
                                }
                            } else if (data === "Unauthorized") {
                                // ignore
                            } else {
                                addErrorAlert(data);
                            }
                    }
                }
            } else if (
                error &&
                error.config &&
                error.config.url === "api/account" &&
                error.config.method === "get"
            ) {
                /* eslint-disable no-console */
                console.log("Authentication Error: Trying to access url api/account with GET.");
            } else if (error && error.message) {
                toast.error(error.message);
            } else {
                toast.error("Unknown error!");
            }
            return Promise.reject(error);
        });
};

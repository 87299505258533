import React from "react";

export function Step5() {
    return (
        <>
            <h2 style={{marginTop: "8px", marginBottom: "16px"}} className="title-custom-style">
                {`Option de facturation`}
            </h2>
            <p>{`Les options de facturation sont sur cette page :`}</p>
            <img src={`images/help/facturation.png`} style={{border: "1px solid #4777"}} />
            <div className={`alert alert-info`} style={{marginTop: "8px"}}>
                <p>{`Logo, couleurs, SIRET, mentions légales...`}</p>
            </div>
        </>
    );
}

import i18n from "i18next";
import translation from "locales/fr/translation.json";
import {initReactI18next} from "react-i18next";

const resources = {
    fr: {
        translation,
    },
};

i18n.use(initReactI18next).init({
    resources,
    fallbackLng: "fr",
    lng: "fr",
    debug: false,
    interpolation: {
        escapeValue: false, // not needed for react as it escapes by default
    },
});

export default i18n;

import {IRootState} from "app/reducers";
import {getSession} from "app/reducers/user/authentication";
import axios from "axios";
import React, {useEffect, useLayoutEffect} from "react";
import ReactGA from "react-ga4";
import {getUrlParameter} from "react-jhipster";
import {useDispatch, useSelector} from "react-redux";
import {Redirect} from "react-router-dom";
import {toast} from "react-toastify";
import {Loading} from "ui-kit/Loading";

const GoogleAuthPage = () => {
    const dispatch = useDispatch();
    const {isModeration, isGoogleAuthenticated} = useSelector(({authentication}: IRootState) => ({
        isModeration: authentication.isModeration,
        isGoogleAuthenticated:
            authentication.account?.googleCredentials === true ||
            authentication.account?.login === "e2e" ||
            authentication.account?.login === "e2e2" /* don't block e2e with google stuff*/,
    }));

    useLayoutEffect(() => {
        try {
            const code = getUrlParameter("code", location.search);
            axios
                .get(`api/google/auth?code=${code}`)
                .then(() => {
                    ReactGA.event("loginGoogle", {method: "code"});
                    window.location.href = `${window.location.origin}`;
                })
                .catch((e) => {
                    if (e.response.status === 406) {
                        toast.error("Merci d'accepter l'accès à l'agenda Google.");
                    } else {
                        toast.error("Impossible de se connecter à l'agenda.");
                    }
                });
        } catch (e) {
            window.location.href = `${window.location.origin}`;
        }
    }, []);

    useEffect(() => {
        if (isModeration) {
            window.location.href = `${window.location.origin}`;
        }
    }, [isModeration]);

    if (isGoogleAuthenticated) {
        return <Redirect to={"/home"} />;
    }

    return <Loading />;
};

export default GoogleAuthPage;

import React from "react";
import {useSelector} from "react-redux";
import {Badge, Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {Brand} from "ui-kit/layout/header/Brand";
import {paymentService} from "common/services/paymentService";
import {IPlatformUser} from "common/types";
import {Splitter} from "ui-kit/Splitter";
import {IRootState} from "../../../reducers";

export function RegisterModal({
    platformUser,
    onRegister,
    onFixRecurringPayment,
}: {
    platformUser: IPlatformUser;
    onRegister: (type: "monthly" | "yearly") => void;
    onFixRecurringPayment: (type: "monthly" | "yearly") => void;
}) {
    const {recurringPaymentFail, previousType} = useSelector(({authentication}: IRootState) => ({
        recurringPaymentFail: !!authentication.account?.lastPayment,
        previousType:
            authentication.account?.lastOrderKind?.toString() === "yearly" ? "yearly" : "monthly",
    }));
    const title = recurringPaymentFail
        ? `Le paiement récurrent a échoué`
        : `Fin de période d'essai`;
    const subtitle = recurringPaymentFail
        ? `Merci de renouveler le paiement de l'abonnement`
        : `Période d'essai terminée !`;
    const description = recurringPaymentFail ? (
        <>
            {`Le paiement récurrent a échoué.`}
            <br />
            {`Il est possible que votre carte soit `}
            <b>{`expirée`}</b>
            {` ou que le paiement récurrent soit impossible avec `}
            <b>{`cette carte`}</b>
            <br />
            {`Merci de choisir une autre carte ou contactez le support.`}
        </>
    ) : (
        <>
            {`Nous espérons que l'essai vous a donné satisfaction !`}
            <br />
            {`Pour continuer cette belle aventure ensemble, merci de choisir un abonnement qui vous convient.`}
            <br />
            <br />
            {`Vous pouvez nous joindre à `}
            <a href="mailto:moderation.mytour@gmail.com?subject=Question au support MyTour">
                {"moderation.mytour@gmail.com"}
            </a>
        </>
    );
    return (
        <Modal isOpen={true} backdrop="static" id="end-of-trial-page" autoFocus={false} centered>
            <ModalHeader id="login-title" close={<></>} className="mytour-header">
                <Brand />
                {title}
            </ModalHeader>
            <ModalBody>
                <p style={{textAlign: "center"}}>
                    <b>{subtitle}</b>
                </p>
                <p style={{textAlign: "center"}}>{description}</p>
            </ModalBody>
            <ModalFooter>
                {recurringPaymentFail ? (
                    <div style={{textAlign: "center", margin: "auto", width: "100%"}}>
                        <Button
                            onClick={() =>
                                onFixRecurringPayment(previousType as "monthly" | "yearly")
                            }
                            color="primary"
                        >
                            payer
                        </Button>
                    </div>
                ) : (
                    <div style={{textAlign: "center", margin: "auto", width: "100%"}}>
                        {paymentService.isDiscount(platformUser) ? (
                            <DiscountPrice onRegister={onRegister} />
                        ) : (
                            <DefaultPrice onRegister={onRegister} />
                        )}
                    </div>
                )}
            </ModalFooter>
        </Modal>
    );
}

function DiscountPrice({onRegister}: {onRegister: (type: "monthly" | "yearly") => void}) {
    return (
        <Splitter>
            <div style={{width: "100%", height: "100%"}}>
                <Button
                    style={{width: "100%", height: "100%"}}
                    onClick={() => onRegister("monthly")}
                    color="primary"
                >
                    <div style={{textAlign: "center"}}>
                        <h4>20€ / mois</h4>
                        <p>
                            Tarif flexible
                            <br />
                            {`Moins d' `}
                            <b>1€/jour</b>
                        </p>{" "}
                    </div>
                </Button>
            </div>
            <div style={{width: "100%", height: "100%"}}>
                <Button
                    style={{width: "100%", height: "100%"}}
                    onClick={() => onRegister("yearly")}
                    color="primary"
                >
                    <div style={{textAlign: "center"}}>
                        <h4>200€ / an</h4>
                        <p>
                            Tarif engagement
                            <br />
                            Économique
                        </p>
                    </div>
                </Button>
            </div>
        </Splitter>
    );
}

function DefaultPrice({onRegister}: {onRegister: (type: "monthly" | "yearly") => void}) {
    return (
        <Splitter>
            <div style={{width: "100%", height: "100%"}}>
                <Button
                    style={{width: "100%", height: "100%"}}
                    onClick={() => onRegister("monthly")}
                    color="primary"
                >
                    <div style={{textAlign: "center"}}>
                        <h4>20€ / mois</h4>
                        <p>
                            Une offre <b>flexible</b>
                            <br />
                            sans engagement
                        </p>
                    </div>
                </Button>
            </div>
            <div style={{width: "100%", height: "100%"}}>
                <Button
                    style={{width: "100%", height: "100%"}}
                    onClick={() => onRegister("yearly")}
                    color="primary"
                >
                    <div style={{textAlign: "center"}}>
                        <h4>
                            <span className="strikethrough-diagonal">240€</span> / an
                        </h4>
                        <p>
                            <Badge
                                color="light"
                                pill
                                style={{
                                    color: "#3976f9",
                                    padding: "0.25em 0.15em",
                                    fontSize: "20px",
                                    marginRight: "4px",
                                }}
                            >
                                -17%
                            </Badge>
                            soit 200€ / an
                            <br />
                            Un tarif <b>avantageux</b>
                        </p>
                    </div>
                </Button>
            </div>
        </Splitter>
    );
}

import React from "react";

type Props = {
    children: React.ReactNode;
};
export function ModalContent({children}: Props) {
    return (
        <div
            style={{
                overflowY: "auto",
                minHeight: "200px",
                maxHeight: "calc(100vh - 200px)",
                alignContent: "end",
                flexDirection: "column",
            }}
        >
            {children}
        </div>
    );
}

export function ModalContentWrapper({children}: Props) {
    return (
        <div
            style={{
                display: "grid",
                overflowY: "hidden",
                gridTemplateRows: "1fr min-content",
                gap: "16px",
                height: "100%",
                width: "100%",
            }}
        >
            {children}
        </div>
    );
}

import React from "react";

export function Step2() {
    return (
        <>
            <h2 style={{marginTop: "8px", marginBottom: "24px"}} className="title-custom-style">
                {`24h avant le rendez-vous`}
            </h2>
            <p style={{marginBottom: "24px"}}>Email de rappel automatique pour le client</p>
            <p style={{fontSize: "14px"}}>
                <i>{`Un rappel 24h avant, cela fait la différence !`}</i>
            </p>
        </>
    );
}

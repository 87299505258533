import {SUCCESS} from "app/reducers/actionType.util";
import axios from "axios";
import {ACTION_TYPES as AUTH_ACTION_TYPES} from "./authentication";

export type OnBoardingStatus = "FEATURES" | "FEATURES2" | "HELP" | "ENDING" | "FINISHED";

export const ACTION_TYPES = {
    SET_STATUS: "authentication/SET_STATUS",
};

const initialState = {
    status: "FINISHED" as OnBoardingStatus,
};

export type OnboardingState = Readonly<typeof initialState>;

// Reducer

export default (state: OnboardingState = initialState, action): OnboardingState => {
    switch (action.type) {
        case ACTION_TYPES.SET_STATUS:
            return {
                ...state,
                status: action.status,
            };
        case SUCCESS(AUTH_ACTION_TYPES.GET_SESSION): {
            const account = action.payload.data;
            if (!account.onboarding) {
                return {
                    ...state,
                    status: "FINISHED",
                };
            } else {
                const hasGoogleCalendar = account.userinfo && account.hasGoogleCalendar;
                if (hasGoogleCalendar) {
                    return {
                        ...state,
                        status: "FEATURES2",
                    };
                }
                return {
                    ...state,
                    status: "FEATURES",
                };
            }
        }
        default:
            return state;
    }
};

export const ignoreOnboarding: () => void = () => async (dispatch, getState) => {
    try {
        await axios.post("api/account/ignore-onboarding");
        await dispatch({
            type: ACTION_TYPES.SET_STATUS,
            status: "FINISHED",
        });
    } catch (e) {
        console.error(e.message);
    }
};

import React from "react";

export function Step6() {
    return (
        <>
            <h2 style={{marginTop: "8px", marginBottom: "16px"}} className="title-custom-style">
                {`Les prestations`}
            </h2>
            <p>{`Les prestations sont sur cette page :`}</p>
            <img src={`images/help/prestation.png`} style={{border: "1px solid #4777"}} />
            <div className={`alert alert-info`} style={{marginTop: "8px"}}>
                <p>{`Le bouton + permet de créer de nouvelles prestations.`}</p>
            </div>
        </>
    );
}

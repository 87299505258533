import {FAILURE, REQUEST, SUCCESS} from "app/reducers/actionType.util";
import axios from "axios";
import {IIncome} from "common/types";
import {IPayload} from "react-jhipster";

export const ACTION_TYPES = {
    FETCH_INCOMES_RANGE: "income/FETCH_INCOMES_RANGE",
    RESET: "income/RESET",
};

const initialState = {
    loading: true,
    errorMessage: null,
    entities: [] as ReadonlyArray<IIncome>,
    totalItems: 0,
    nextPage: 0,
    loadingNext: false,
};

export type IncomeRangeState = Readonly<typeof initialState>;

// Reducer

export default (state: IncomeRangeState = initialState, action): IncomeRangeState => {
    switch (action.type) {
        case REQUEST(ACTION_TYPES.FETCH_INCOMES_RANGE):
            return {
                ...state,
                errorMessage: null,
                loading: true,
            };
        case FAILURE(ACTION_TYPES.FETCH_INCOMES_RANGE):
            return {
                ...state,
                loadingNext: false,
                loading: false,
                errorMessage: action.payload,
            };
        case SUCCESS(ACTION_TYPES.FETCH_INCOMES_RANGE):
            return {
                ...state,
                loading: false,
                entities: action.payload.data,
            };
        case ACTION_TYPES.RESET:
            return {
                ...initialState,
            };
        default:
            return state;
    }
};

// Actions

export declare type ICrudGetAllAction<T> = (
    from?: string,
    to?: string
) => IPayload<T> | ((dispatch: any) => IPayload<T>);

export const getIncomesRange: ICrudGetAllAction<IIncome> = (from, to) => {
    const requestUrl = `api/incomes/range/${from && to ? `?from=${from}&to=${to}` : ""}`;
    return {
        type: ACTION_TYPES.FETCH_INCOMES_RANGE,
        payload: axios.get<IIncome>(
            `${requestUrl}${from && to ? "&" : "?"}cacheBuster=${new Date().getTime()}`
        ),
    };
};

export const reset = () => ({
    type: ACTION_TYPES.RESET,
});

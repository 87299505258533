import {FAILURE, REQUEST, SUCCESS} from "app/reducers/actionType.util";
import {cleanEntity} from "app/services/entity.service";
import axios from "axios";
import {IEvent, IQuotationRequest, IUpdateInvoice, IEventCreatePayload} from "common/types";
import {
    ICrudDeleteAction,
    ICrudGetAction,
    ICrudPutAction,
    IPayload,
    IPayloadResult,
} from "react-jhipster";

export const ACTION_TYPES = {
    FETCH_NEXT_EVENTS: "event/FETCH_NEXT_EVENTS",
    REFRESH_EVENTS: "event/REFRESH_EVENTS",
    FETCH_EVENT: "event/FETCH_EVENT",
    CREATE_EVENT: "event/CREATE_EVENT",
    UPDATE_EVENT: "event/UPDATE_EVENT",
    DELETE_EVENT: "event/DELETE_EVENT",
    ARCHIVE_EVENT: "event/ARCHIVE_EVENT",
    RESET: "event/RESET",
};

const initialState = {
    loading: true,
    loadingNext: false,
    errorMessage: null,
    entities: [] as ReadonlyArray<IEvent>,
    entity: null as Readonly<IEvent> | null,
    updating: false,
    totalItems: 0,
    totalInLate: 0,
    totalToBePaid: 0,
    nextPage: 0,
    updateSuccess: false,
};

export type EventState = Readonly<typeof initialState>;

// Reducer

export default (state: EventState = initialState, action): EventState => {
    switch (action.type) {
        case REQUEST(ACTION_TYPES.FETCH_EVENT):
            return {
                ...state,
                errorMessage: null,
                updateSuccess: false,
                loading: true,
                loadingNext: false,
            };
        case REQUEST(ACTION_TYPES.FETCH_NEXT_EVENTS):
            return {
                ...state,
                errorMessage: null,
                updateSuccess: false,
                loadingNext: true,
            };
        case REQUEST(ACTION_TYPES.REFRESH_EVENTS):
            return {
                ...state,
                errorMessage: null,
                updateSuccess: false,
                loading: true,
                loadingNext: false,
            };
        case REQUEST(ACTION_TYPES.CREATE_EVENT):
        case REQUEST(ACTION_TYPES.UPDATE_EVENT):
        case REQUEST(ACTION_TYPES.DELETE_EVENT):
        case REQUEST(ACTION_TYPES.ARCHIVE_EVENT):
        case FAILURE(ACTION_TYPES.FETCH_NEXT_EVENTS):
        case FAILURE(ACTION_TYPES.REFRESH_EVENTS):
        case FAILURE(ACTION_TYPES.FETCH_EVENT):
        case FAILURE(ACTION_TYPES.CREATE_EVENT):
        case FAILURE(ACTION_TYPES.UPDATE_EVENT):
        case FAILURE(ACTION_TYPES.DELETE_EVENT):
        case FAILURE(ACTION_TYPES.ARCHIVE_EVENT):
            return {
                ...state,
                loading: false,
                loadingNext: false,
                updating: false,
                updateSuccess: false,
                errorMessage: action.payload,
            };
        case SUCCESS(ACTION_TYPES.FETCH_NEXT_EVENTS):
            return {
                ...state,
                loading: false,
                loadingNext: false,
                nextPage: state.nextPage + 1,
                entities: [...state.entities, ...action.payload.data],
                totalItems: parseInt(action.payload.headers["x-total-count"], 10),
                ...(state.nextPage === 0
                    ? {
                          totalInLate: parseInt(action.payload.headers["x-total-inlate"], 10),
                          totalToBePaid: parseInt(action.payload.headers["x-total-tobepaid"], 10),
                      }
                    : {}),
            };
        case SUCCESS(ACTION_TYPES.REFRESH_EVENTS):
            return {
                ...state,
                loading: false,
                loadingNext: false,
                nextPage: state.nextPage,
                entities: [...action.payload.data],
                totalItems: parseInt(action.payload.headers["x-total-count"], 10),
            };
        case SUCCESS(ACTION_TYPES.FETCH_EVENT):
            return {
                ...state,
                loading: false,
                loadingNext: false,
                entity: action.payload.data,
            };
        case SUCCESS(ACTION_TYPES.CREATE_EVENT):
        case SUCCESS(ACTION_TYPES.UPDATE_EVENT):
            return {
                ...state,
                updating: false,
                updateSuccess: true,
                entity: action.payload.data,
            };
        case SUCCESS(ACTION_TYPES.DELETE_EVENT):
        case SUCCESS(ACTION_TYPES.ARCHIVE_EVENT):
            return {
                ...state,
                updating: false,
                updateSuccess: true,
                entity: null as Readonly<IEvent> | null,
            };
        case ACTION_TYPES.RESET:
            return {
                ...initialState,
            };
        default:
            return state;
    }
};

const apiUrl = "api/events";

// Actions

export declare type ICrudGetAllAction2<T> = (
    withPaymentLate: boolean,
    withToBePaid: boolean,
    page: number,
    size: number,
    sort?: string
) => IPayload<T> | ((dispatch: any) => IPayload<T>);

export declare type ICrudGetAllAction3<T> = (
    withPaymentLate: boolean,
    withToBePaid: boolean,
    size?: number,
    sort?: string
) => IPayload<T> | ((dispatch: any) => IPayload<T>);

export const nextEntities: ICrudGetAllAction2<IEvent[]> = (
    withPaymentLate: boolean,
    withToBePaid: boolean,
    page?: number,
    size?: number
) => {
    const requestUrl = `${apiUrl}/v2/${
        page
            ? `?withPaymentLate=${withPaymentLate}&withToBePaid=${withToBePaid}&page=${page}&size=${size}`
            : `?withPaymentLate=${withPaymentLate}&withToBePaid=${withToBePaid}`
    }`;
    return {
        type: ACTION_TYPES.FETCH_NEXT_EVENTS,
        payload: axios.get<IEvent[]>(`${requestUrl}&cacheBuster=${new Date().getTime()}`),
    };
};

export const refreshEntities: ICrudGetAllAction3<IEvent> = (
    withPaymentLate: boolean,
    withToBePaid: boolean,
    size
) => {
    const requestUrl = `${apiUrl}/v2/${
        size
            ? `?withPaymentLate=${withPaymentLate}&withToBePaid=${withToBePaid}&page=0&size=${size}`
            : `?withPaymentLate=${withPaymentLate}&withToBePaid=${withToBePaid}`
    }`;
    return {
        type: ACTION_TYPES.REFRESH_EVENTS,
        payload: axios.get<IEvent>(`${requestUrl}&cacheBuster=${new Date().getTime()}`),
    };
};

export const getEntity: ICrudGetAction<IEvent> = (id) => {
    const requestUrl = `${apiUrl}/${id}`;
    return {
        type: ACTION_TYPES.FETCH_EVENT,
        payload: axios.get<IEvent>(requestUrl),
    };
};

export declare type ICrudPutAction2<T, T2> = (data?: T) => IPayload<T2> | IPayloadResult<T2>;

export const createEntity: ICrudPutAction2<IEventCreatePayload, IEvent> =
    (entity) => async (dispatch) => {
        const result = await dispatch({
            type: ACTION_TYPES.CREATE_EVENT,
            payload: axios.post(apiUrl, cleanEntity(entity)),
        });
        return result;
    };

export const updateEntity: ICrudPutAction<IEvent> = (entity) => async (dispatch) => {
    const result = await dispatch({
        type: ACTION_TYPES.UPDATE_EVENT,
        payload: axios.put(apiUrl, cleanEntity(entity)),
    });
    return result;
};

export const deleteEntity: ICrudDeleteAction<IEvent> = (id) => async (dispatch) => {
    const requestUrl = `${apiUrl}/${id}`;
    const result = await dispatch({
        type: ACTION_TYPES.DELETE_EVENT,
        payload: axios.delete(requestUrl),
    });
    return result;
};

export const archiveEntity: ICrudDeleteAction<void> = (id) => async (dispatch) => {
    const requestUrl = `${apiUrl}/archive/${id}`;
    const result = await dispatch({
        type: ACTION_TYPES.ARCHIVE_EVENT,
        payload: axios.post(requestUrl),
    });
    return result;
};

export const unarchiveEntity = (id: string) => async (dispatch) => {
    const requestUrl = `${apiUrl}/unarchive/${id}`;
    const result = await dispatch({
        type: ACTION_TYPES.FETCH_EVENT,
        payload: axios.post(requestUrl),
    });
    return result;
};

export const reset = () => ({
    type: ACTION_TYPES.RESET,
});

export const quotationRequest =
    (eventId: string, request: IQuotationRequest) => async (dispatch) => {
        const requestUrl = `${apiUrl}/${eventId}/quotation`;
        const result = await dispatch({
            type: ACTION_TYPES.UPDATE_EVENT,
            payload: axios.post<IEvent>(requestUrl, cleanEntity(request)),
        });
        return result;
    };

export const quotationFile = (eventId: string, blob: Blob, fileName) => async (dispatch) => {
    const formData = new FormData();
    const file = new File([blob], fileName, {
        type: "application/pdf",
    });
    formData.append("file", file);

    const requestUrl = `${apiUrl}/${eventId}/quotation/file`;
    const result = await dispatch({
        type: ACTION_TYPES.UPDATE_EVENT,
        payload: axios.post<IEvent>(requestUrl, formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        }),
    });
    return result;
};

export const sendQuotation = (eventId: string, fileName) => async (dispatch) => {
    const requestUrl = `${apiUrl}/${eventId}/quotation/send`;
    const result = await dispatch({
        type: ACTION_TYPES.UPDATE_EVENT,
        payload: axios.post<IEvent>(requestUrl, {fileName}),
    });
    return result;
};

export const archiveQuotation: ICrudGetAction<void> = (id) => {
    const requestUrl = `${apiUrl}/${id}/quotation/archive`;
    return {
        type: ACTION_TYPES.UPDATE_EVENT,
        payload: axios.post<void>(requestUrl),
    };
};

// don't update the state (should not displayed directly)
export async function finalizingInvoice(eventId: string) {
    const requestUrl = `${apiUrl}/${eventId}/invoice/finalizing`;
    const response = await axios.post<IEvent>(requestUrl);
    return response.data;
}

export const finalizeInvoice = (eventId: string, blob: Blob, fileName) => async (dispatch) => {
    const formData = new FormData();
    const file = new File([blob], fileName, {
        type: "application/pdf",
    });
    formData.append("file", file);

    const requestUrl = `${apiUrl}/${eventId}/invoice/finalize`;
    const result = await dispatch({
        type: ACTION_TYPES.UPDATE_EVENT,
        payload: axios.post<IEvent>(requestUrl, formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        }),
    });
    return result;
};

export const updateInvoicePayment =
    (eventId: string, invoice: IUpdateInvoice) => async (dispatch) => {
        const requestUrl = `${apiUrl}/${eventId}/invoice/update`;
        const result = await dispatch({
            type: ACTION_TYPES.UPDATE_EVENT,
            payload: axios.put<void>(requestUrl, cleanEntity(invoice)),
        });
        return result;
    };

export const sendReminder = (eventId: string) => async (dispatch) => {
    const requestUrl = `${apiUrl}/${eventId}/invoice/reminder`;
    const result = await dispatch({
        type: ACTION_TYPES.UPDATE_EVENT,
        payload: axios.post<IEvent>(requestUrl),
    });
    return result;
};

export const creditingInvoice = (eventId: string) => async (dispatch) => {
    const requestUrl = `${apiUrl}/${eventId}/invoice/crediting`;
    const result = await dispatch({
        type: ACTION_TYPES.UPDATE_EVENT,
        payload: axios.post<IEvent>(requestUrl),
    });
    return result;
};

export const creditInvoice =
    (eventId: string, blob: Blob, fileName: string, sendEmail: boolean) => async (dispatch) => {
        const formData = new FormData();
        const file = new File([blob], fileName, {
            type: "application/pdf",
        });
        formData.append("file", file);
        formData.append("sendEmail", JSON.stringify(sendEmail));

        const requestUrl = `${apiUrl}/${eventId}/invoice/credit`;
        const result = await dispatch({
            type: ACTION_TYPES.UPDATE_EVENT,
            payload: axios.post<IEvent>(requestUrl, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            }),
        });
        return result;
    };

import * as Sentry from "@sentry/react";
import "app/App.scss";
import {IRootState} from "app/reducers";
import "config/dayjs.ts";
import {useEffect} from "react";
import ReactGA from "react-ga4";
import {useSelector} from "react-redux";
import "react-toastify/dist/ReactToastify.css";
const baseHref = document?.querySelector("base")?.getAttribute("href")?.replace(/\/$/, "");

export function SentryContainer() {
    const email = useSelector(({authentication}: IRootState) => authentication.account?.email);
    const id = useSelector(({authentication}: IRootState) => authentication.account?.id);
    const login = useSelector(({authentication}: IRootState) => authentication.account?.login);
    const isModeration = useSelector(
        ({authentication}: IRootState) => authentication.isModeration
    );

    useEffect(() => {
        if (email || id || login || isModeration) {
            ReactGA.set({userId: id});
            Sentry.setUser({id, login, email, isModeration});
        } else {
            Sentry.setUser(null);
        }
    }, [email, id, login, isModeration]);

    return null;
}

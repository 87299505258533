import {IRootState} from "app/reducers";
import React from "react";
import {Trans as Translate, useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {MenuItem} from "ui-kit/layout/menus/MenuItem";
import {NavDropdown} from "./NavDropdown";

const AccountMenuItemsAuthenticated = (props) => (
    <>
        <MenuItem
            icon="circle-info"
            to="/account/support"
            onClick={props.onClick}
            data-cy="support"
        >
            Support
        </MenuItem>
        <MenuItem icon="wrench" to="/account/settings" onClick={props.onClick} data-cy="settings">
            <Translate i18nKey="global.menu.account.settings">Settings</Translate>
        </MenuItem>
        <MenuItem
            icon="lock"
            to="/account/password"
            onClick={props.onClick}
            data-cy="passwordItem"
        >
            <Translate i18nKey="global.menu.account.password">Password</Translate>
        </MenuItem>
        <MenuItem icon="sign-out-alt" to="/logout" onClick={props.onClick} data-cy="logout">
            <Translate i18nKey="global.menu.account.logout">Sign out</Translate>
        </MenuItem>
    </>
);

const AccountMenuItems = (props) => (
    <>
        <MenuItem id="login-item" icon="sign-in-alt" to="/login" data-cy="login">
            <Translate i18nKey="global.menu.account.login">Sign in</Translate>
        </MenuItem>
    </>
);

const ConfigMenuItemsAuthenticated = (props) => (
    <>
        <MenuItem icon="list" to="/account/features" onClick={props.onClick} data-cy="features">
            <Translate i18nKey="global.menu.account.features">Features</Translate>
        </MenuItem>
        <MenuItem
            icon="file-invoice-dollar"
            to="/account/invoice-settings"
            data-cy="invoice-settings"
            onClick={props.onClick}
        >
            <Translate i18nKey="global.menu.account.invoiceSettings">InvoiceSettings</Translate>
        </MenuItem>
        <MenuItem
            icon="clipboard-list"
            to="/service-description"
            data-cy="service-description"
            onClick={props.onClick}
        >
            <Translate i18nKey="global.menu.entities.serviceDescription" />
        </MenuItem>
    </>
);

export function AccountMenu({onClick}: {onClick: () => void}) {
    const {t: translate} = useTranslation();
    const login = useSelector(({authentication}: IRootState) => authentication.account?.login);
    const isAuthenticated = useSelector(
        ({authentication}: IRootState) => authentication.isAuthenticated
    );

    let loginDisplay = login;
    if (login && login.length > 10) {
        loginDisplay = login.substring(0, 8) + "…";
    }
    return (
        <>
            <NavDropdown
                icon="wrench"
                name={translate("global.menu.account.config")}
                id="config-menu"
            >
                {isAuthenticated && <ConfigMenuItemsAuthenticated onClick={onClick} />}
            </NavDropdown>

            <NavDropdown icon="user" name={loginDisplay} id="account-menu" data-cy="accountMenu">
                {isAuthenticated ? (
                    <AccountMenuItemsAuthenticated onClick={onClick} />
                ) : (
                    <AccountMenuItems onClick={onClick} />
                )}
            </NavDropdown>
        </>
    );
}

import {IRootState} from "app/reducers";
import {login} from "app/reducers/user/authentication";
import {AvField, AvForm} from "availity-reactstrap-validation";
import React from "react";
import ReactGA from "react-ga4";
import {Trans as Translate, useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {Alert, Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {Brand} from "ui-kit/layout/header/Brand";

const Link = (props) => <a href={props.to} {...props} />;

export const LoginModal = () => {
    const dispatch = useDispatch();
    const {authentication} = useSelector(({authentication}: IRootState) => ({
        authentication,
    }));
    const {loginError} = authentication;

    const {t: translate} = useTranslation();
    const handleSubmit = (event, errors, {username, password, rememberMe}) => {
        event.stopPropagation();
        dispatch(login(username, password, rememberMe));
        ReactGA.event("login", {method: "LoginForm"});
    };

    return (
        <Modal isOpen={true} backdrop="static" id="login-page" autoFocus={false} centered>
            <AvForm onSubmit={handleSubmit}>
                <ModalHeader id="login-title" close={<></>} className="mytour-header">
                    <Brand />
                    <Translate i18nKey="login.title">Sign in</Translate>
                </ModalHeader>
                <ModalBody>
                    {loginError ? (
                        <Alert color="danger" id="login-error">
                            <Translate i18nKey="login.messages.error.authentication">
                                <strong>Failed to sign in!</strong> Please check your credentials
                                and try again.
                            </Translate>
                        </Alert>
                    ) : null}
                    <AvField
                        name="username"
                        label={translate("global.form.username.label")}
                        placeholder={translate("global.form.username.placeholder")}
                        required
                        errorMessage={translate("login.form.notempty")}
                        autoFocus
                    />
                    <AvField
                        name="password"
                        type="password"
                        label={translate("login.form.password")}
                        placeholder={translate("login.form.passwordplaceholder")}
                        required
                        errorMessage={translate("login.form.notempty")}
                    />
                    <div className="mt-1">&nbsp;</div>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" tag={Link} to="https://mytour.tech/#registration">
                        Inscription
                    </Button>
                    <Button color="primary" type="submit">
                        <Translate i18nKey="login.form.button">Sign in</Translate>
                    </Button>
                </ModalFooter>
            </AvForm>
        </Modal>
    );
};

import React from "react";

export function Step3() {
    return (
        <>
            <h2 style={{marginTop: "8px", marginBottom: "16px"}} className="title-custom-style">
                {`Les facture`}
            </h2>
            <p>{`Les factures sont sur cette page :`}</p>
            <img src={`images/help/facture.png`} style={{border: "1px solid #4777"}} />
            <div className={`alert alert-info`} style={{marginTop: "8px"}}>
                <p>
                    {`L'export vous permet de télécharger un tableau de l'activité prestations de service et vente.`}
                    <br />
                    <br />
                    <strong>{`Idéal pour la déclaration d'activité URSSAF.`}</strong>
                </p>
            </div>
        </>
    );
}

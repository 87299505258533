import {zodResolver} from "@hookform/resolvers/zod";
import {updateEntity} from "app/reducers/user/platformUser.reducer";
import {featureService} from "common/services/featureService";
import {IAccount, IPlatformUser} from "common/types";
import React, {useState} from "react";
import {useForm} from "react-hook-form";
import {useDispatch} from "react-redux";
import {z} from "zod";
import {SplitterButtons} from "ui-kit/Splitter";
import {ACTION_TYPES} from "../../../../reducers/user/onboarding";
import {syncExec} from "../../../../services/dispatch.service";
import {NoButton} from "ui-kit/form/button/NoButton";
import {YesButton} from "ui-kit/form/button/YesButton";
import {Step2} from "./components/Step2";
import {Step3} from "./components/Step3";
import {ModalContent, ModalContentWrapper} from "../../ModalContent";

type AStep = 0 | 1;

type Props = {account: IAccount; platformUser: IPlatformUser};

const schema = z.object({
    notifDayBefore: z.boolean(),
    notifAtSave: z.boolean(),
});
type Form = z.infer<typeof schema>;

export function AccountFeatures({account, platformUser}: Props) {
    const [pending, setPending] = useState(false);
    const [step, setStep] = useState<AStep>(0);
    const dispatch = useDispatch();

    const {notifAtSave, notifDayBefore, syncAgenda} = featureService.getFeatures(platformUser);

    const form = useForm<Form>({
        resolver: zodResolver(schema),
        defaultValues: {notifAtSave, notifDayBefore},
    });

    const firstStep = 0;
    const lastStep = 1;

    return (
        <ModalContentWrapper>
            <ModalContent>
                {step === firstStep && <Step2 />}
                {step === lastStep && <Step3 />}
            </ModalContent>
            {step === 0 && (
                <>
                    <SplitterButtons>
                        <NoButton
                            onClick={() => {
                                form.setValue("notifDayBefore", false);
                                handleToNextStep();
                            }}
                            disabled={pending}
                            pending={pending}
                        />
                        <YesButton
                            onClick={() => {
                                form.setValue("notifDayBefore", true);
                                handleToNextStep();
                            }}
                            disabled={pending}
                            pending={pending}
                        />
                    </SplitterButtons>
                </>
            )}
            {step === 1 && (
                <>
                    <SplitterButtons>
                        <NoButton
                            onClick={() => {
                                form.setValue("notifAtSave", false);
                                handleSubmit();
                            }}
                            disabled={pending}
                            pending={pending}
                        />
                        <YesButton
                            onClick={() => {
                                form.setValue("notifAtSave", true);
                                handleSubmit();
                            }}
                            disabled={pending}
                            pending={pending}
                        />
                    </SplitterButtons>
                </>
            )}
        </ModalContentWrapper>
    );

    function handleToNextStep() {
        setPending(true);
        try {
            const nextStep = step + 1;
            if (nextStep <= lastStep) {
                setStep(nextStep as AStep);
            }
        } finally {
            setPending(false);
        }
    }

    async function handleSubmit() {
        setPending(true);
        try {
            const {notifAtSave, notifDayBefore} = form.getValues();
            const features = {notifAtSave, notifDayBefore, syncAgenda};
            const entity = {
                id: platformUser?.id,
                features: JSON.stringify(features),
            };
            await syncExec(dispatch(updateEntity(entity)));
            handleHelp();
        } finally {
            setPending(false);
        }
    }

    function handleHelp() {
        dispatch({
            type: ACTION_TYPES.SET_STATUS,
            status: "HELP",
        });
    }
}

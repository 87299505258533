import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React from "react";
import {Button, Spinner} from "reactstrap";

type Props = {
    onClick: () => void;
    disabled: boolean;
    pending: boolean;
};

export function NextButton({onClick, disabled, pending}: Props) {
    return (
        <Button
            id="nextButton"
            type="button"
            color="success"
            onClick={onClick}
            disabled={disabled}
        >
            <span>{pending && <Spinner size="sm" color="light" />} Suite</span>
            &nbsp;
            <FontAwesomeIcon icon="arrow-right" />
        </Button>
    );
}

import {IPayload, IPayloadResult} from "react-jhipster";

export const syncExec = async <T>(payload: IPayload<T> | IPayloadResult<T>) => {
    try {
        if (payload instanceof Promise) {
            const result = await payload;
            if (
                result?.value?.status === 200 ||
                result?.value?.status === 201 ||
                result?.value?.status === 202
            ) {
                return result?.value?.data as T;
            }
        }
    } catch (err) {
        console.error(err.message);
    }
};

import {FAILURE, REQUEST, SUCCESS} from "app/reducers/actionType.util";
import {getSession} from "app/reducers/user/authentication";
import axios from "axios";
import {IUser} from "common/types";
import i18n from "config/i18n";
import {ICrudPutAction, Storage} from "react-jhipster";
const {t: translate} = i18n;

export const ACTION_TYPES = {
    UPDATE_ACCOUNT: "account/UPDATE_ACCOUNT",
    RESET: "account/RESET",
};

const initialState = {
    loading: false,
    updating: false,
    errorMessage: null,
    updateSuccess: false,
    updateFailure: false,
};

export type SettingsState = Readonly<typeof initialState>;

// Reducer
export default (state: SettingsState = initialState, action): SettingsState => {
    switch (action.type) {
        case REQUEST(ACTION_TYPES.UPDATE_ACCOUNT):
            return {
                ...state,
                errorMessage: null,
                updateSuccess: false,
                loading: true,
                updating: true,
            };
        case FAILURE(ACTION_TYPES.UPDATE_ACCOUNT):
            return {
                ...state,
                loading: false,
                updating: false,
                updateSuccess: false,
                updateFailure: true,
            };
        case SUCCESS(ACTION_TYPES.UPDATE_ACCOUNT):
            return {
                ...state,
                loading: false,
                updating: false,
                updateSuccess: true,
                updateFailure: false,
            };
        case ACTION_TYPES.RESET:
            return {
                ...initialState,
            };
        default:
            return state;
    }
};

// Actions
const apiUrl = "api/account";

export const saveAccountSettings: ICrudPutAction<IUser> = (account) => async (dispatch) => {
    await dispatch({
        type: ACTION_TYPES.UPDATE_ACCOUNT,
        payload: axios.post(apiUrl, account),
    });

    if (Storage.session.get(`locale`)) {
        Storage.session.remove(`locale`);
    }

    return await dispatch(getSession());
};

export const reset = () => ({
    type: ACTION_TYPES.RESET,
});

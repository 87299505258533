import dayjs from "dayjs";
// jhipster-needle-i18n-language-dayjs-imports - JHipster will import languages from dayjs here
import "dayjs/locale/fr";
import customParseFormat from "dayjs/plugin/customParseFormat";
import duration from "dayjs/plugin/duration";
import relativeTime from "dayjs/plugin/relativeTime";

dayjs.locale("fr");
// DAYJS CONFIGURATION
dayjs.extend(customParseFormat);
dayjs.extend(duration);
dayjs.extend(relativeTime);

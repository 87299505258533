import React from "react";

export function Step0() {
    return (
        <>
            <p style={{marginBottom: "24px"}}>{`Synchroniser l'agenda Google avec MyTour !`}</p>
            <p style={{fontSize: "14px"}}>
                <i>{`Démarrez le GPS en 2 secondes avec l'adresse des rendez-vous présent sur dans l'agenda`}</i>
            </p>
        </>
    );
}

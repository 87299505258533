import {IRootState} from "app/reducers";
import {getEntities as getCustomers} from "app/reducers/customer.reducer";
import {paymentService} from "common/services/paymentService";
import {OrderKind} from "common/types";
import dayjs from "dayjs";
import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useLocation} from "react-router-dom";
import {featureService} from "common/services/featureService";
import {AcceptCGUModal, CGU} from "./features/login/AcceptCGUModal";
import {GoogleModal} from "./features/login/GoogleModal";
import {PaymentModal} from "./features/login/PaymentModal";
import {Onboarding} from "./features/onboarding/Onboarding";
import {loadCurrentEntity} from "./reducers/user/platformUser.reducer";

export function Modals() {
    const dispatch = useDispatch();

    const isModeration = useSelector(
        ({authentication}: IRootState) => authentication.isModeration
    );
    const account = useSelector(({authentication}: IRootState) => authentication.account);
    const platformUser = useSelector(({platformUser}: IRootState) => platformUser.entity);
    const status = useSelector(({onboarding}: IRootState) => onboarding.status);
    const location = useLocation();
    if (!account) {
        return null;
    }
    if (location.pathname === "/google/auth") {
        return null; // login with google
    }
    dispatch(loadCurrentEntity());
    if (!platformUser) {
        return null;
    }
    const cgu = account.cgu;
    if (cgu !== CGU) {
        let cguUpdated = false;
        if (cgu !== null && cgu !== undefined) {
            cguUpdated = cgu !== CGU;
        }
        return <AcceptCGUModal account={account} cguUpdated={cguUpdated} />;
    }
    const createdDate = account?.createdDate ? dayjs(account?.createdDate).toDate() : null;
    const lastPayment = account?.lastPayment ? dayjs(account?.lastPayment).toDate() : null;
    const now = new Date();
    const endOfTrial = paymentService.endOfTrial(createdDate, lastPayment, now);

    const displayPayment =
        (lastPayment === null && endOfTrial && account?.enablePayment) ||
        (lastPayment !== null &&
            paymentService.shouldTriggerRecurringPayment(
                lastPayment,
                account?.lastOrderKind ?? OrderKind.MONTHLY,
                now
            ) &&
            account?.enablePayment);
    if (displayPayment) {
        return <PaymentModal platformUser={platformUser} />;
    }
    if (status !== "FINISHED") {
        return <Onboarding account={account} platformUser={platformUser} status={status} />;
    }
    let syncAgenda = false;
    const isGoogleAuthenticated =
        account?.googleCredentials === true ||
        account?.login === "e2e" ||
        account?.login === "e2e2"; /* don't block e2e with google stuff*/
    if (platformUser) {
        const features = featureService.getFeatures(platformUser);
        syncAgenda = features.syncAgenda;
    }
    if (syncAgenda && !isModeration && !isGoogleAuthenticated) {
        return <GoogleModal />;
    }
    return null;
}

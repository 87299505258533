import {IRootState} from "app/reducers";
import {updateEntity} from "app/reducers/user/platformUser.reducer";
import {syncExec} from "app/services/dispatch.service";
import React from "react";
import {Trans as Translate, useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {useLocation} from "react-router-dom";
import {Alert, Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {Brand} from "ui-kit/layout/header/Brand";
import {featureService} from "common/services/featureService";

export const GoogleModal = () => {
    const dispatch = useDispatch();
    const {t: translate} = useTranslation();
    const location = useLocation();

    const googleError = new URLSearchParams(location.search).get("error");

    const {account, platformUser} = useSelector(({authentication, platformUser}: IRootState) => ({
        account: authentication.account,
        platformUser: platformUser.entity,
    }));

    const Link = (props2) => <a href={props2.to} {...props2} />;
    return (
        <Modal isOpen={true} backdrop="static" id="login-page" autoFocus={false} centered>
            <ModalHeader id="login-title" close={<></>} className="mytour-header">
                <Brand />
                <Translate i18nKey="google.title">Sign in</Translate>
            </ModalHeader>
            <ModalBody>
                {googleError && (
                    <Alert color="danger">
                        <strong>{translate(`google.error.${googleError}`)}</strong>
                    </Alert>
                )}
                <p>
                    <Translate i18nKey="google.description">You need a google account.</Translate>
                </p>
                <p>
                    <Translate i18nKey="google.description2">
                        <strong>You need a google account.</strong>
                    </Translate>
                </p>
            </ModalBody>
            <ModalFooter>
                <Button color="secondary" onClick={onAbort} id="abort">
                    Ne pas synchroniser
                </Button>
                <Button tag={Link} color="primary" to={account?.authUrl} style={{float: "right"}}>
                    <Translate i18nKey="google.button">Login</Translate>
                </Button>
            </ModalFooter>
        </Modal>
    );
    async function onAbort() {
        if (platformUser) {
            const features = featureService.getFeatures(platformUser);
            const entity = {
                id: platformUser?.id,
                features: JSON.stringify({...features, syncAgenda: false}),
            };
            await syncExec(dispatch(updateEntity(entity)));
        }
    }
};

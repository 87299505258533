import React from "react";
export function Tracking() {
    return (
        <>
            <script async src="https://www.googletagmanager.com/gtag/js?id=G-C7M609QMCS"></script>
            <script>
                {`
      window.dataLayer = window.dataLayer || [];
      function gtag(){
        dataLayer.push(arguments);
      }
      gtag('js', new Date());

      gtag('config', 'G-C7M609QMCS');`}
            </script>
        </>
    );
}

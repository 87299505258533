import React from "react";

export function Step2() {
    return (
        <>
            <h2 style={{marginTop: "8px", marginBottom: "16px"}} className="title-custom-style">
                {`Les clients`}
            </h2>
            <p>{`Les clients sont sur cette page :`}</p>
            <img src={`images/help/client.png`} style={{border: "1px solid #4777"}} />
            <div className={`alert alert-info`} style={{marginTop: "8px"}}>
                <p>
                    {`Une barre de recherche permet de trouver rapidement un client.`}
                    <br />
                    <br />
                    {`Le bouton + permet de créer un nouveau client.`}
                </p>
            </div>
        </>
    );
}

import React from "react";
import {Trans as Translate} from "react-i18next";
import {Alert} from "reactstrap";

class PageNotFound extends React.Component {
    render() {
        return (
            <div>
                <Alert color="danger">
                    <Translate i18nKey="error.http.404">The page does not exist.</Translate>
                </Alert>
            </div>
        );
    }
}

export default PageNotFound;

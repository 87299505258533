// prettier-ignore
// prettier-ignore
// prettier-ignore
// prettier-ignore
import customer, { CustomerState } from "app/reducers/customer.reducer";
import event, {EventState} from "app/reducers/event.reducer";
import agendaEvent, {AgendaEventState} from "app/reducers/agendaEvent.reducer";
// prettier-ignore
import invoice, {
  InvoiceState
} from "app/reducers/invoice.reducer";
import incomesByDate, {IncomeByDateState} from "app/reducers/incomesByDate.reducer";
import incomesRange, {IncomeRangeState} from "app/reducers/incomesRange.reducer";
// prettier-ignore
import serviceDescription, { ServiceDescriptionState } from "app/reducers/serviceDescription.reducer";
import serviceTarget, {ServiceTargetState} from "app/reducers/serviceTarget.reducer";
// prettier-ignore
import service, {
  ServiceState
} from "app/reducers/service.reducer";
import password, {PasswordState} from "app/reducers/user/password.reducer";
import passwordReset, {PasswordResetState} from "app/reducers/user/passwordReset.reducer";
import platformUser, {PlatformUserState} from "app/reducers/user/platformUser.reducer";
import settings, {SettingsState} from "app/reducers/user/settings.reducer";
import {loadingBarReducer as loadingBar} from "react-redux-loading-bar";
import {combineReducers} from "redux";
import invoiceFile, {InvoiceFileState} from "./invoiceFile.reducer";
import quotationFile, {QuotationFileState} from "./quotationFile.reducer";
import authentication, {AuthenticationState} from "./user/authentication";
import onboarding, {OnboardingState} from "./user/onboarding";

/* jhipster-needle-add-reducer-import - JHipster will add reducer here */

export interface IRootState {
    readonly authentication: AuthenticationState;
    readonly onboarding: OnboardingState;
    readonly passwordReset: PasswordResetState;
    readonly password: PasswordState;
    readonly settings: SettingsState;
    readonly customer: CustomerState;
    readonly serviceTarget: ServiceTargetState;
    readonly serviceDescription: ServiceDescriptionState;
    readonly service: ServiceState;
    readonly event: EventState;
    readonly agendaEvent: AgendaEventState;
    readonly invoice: InvoiceState;
    readonly incomesByDate: IncomeByDateState;
    readonly incomesRange: IncomeRangeState;
    readonly invoiceFile: InvoiceFileState;
    readonly quotationFile: QuotationFileState;
    readonly platformUser: PlatformUserState;
    /* jhipster-needle-add-reducer-type - JHipster will add reducer type here */
    readonly loadingBar: any;
}

const rootReducer = combineReducers<IRootState>({
    authentication,
    onboarding,
    passwordReset,
    password,
    settings,
    customer,
    serviceTarget,
    serviceDescription,
    service,
    event,
    agendaEvent,
    invoice,
    incomesByDate,
    incomesRange,
    invoiceFile,
    quotationFile,
    platformUser,
    /* jhipster-needle-add-reducer-combine - JHipster will add reducer here */
    loadingBar,
});

export default rootReducer;

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React, {useState} from "react";
import LoadingBar from "react-redux-loading-bar";
import {Collapse, Nav, Navbar, NavbarToggler} from "reactstrap";
import {AccountMenu, EntitiesMenu} from "../menus";
import {Brand} from "./Brand";
import "./header.scss";

export function Header() {
    const [menuOpen, setMenuOpen] = useState(false);

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    /* jhipster-needle-add-element-to-menu - JHipster will add new menu items here */

    return (
        <div id="app-header">
            <LoadingBar className="loading-bar" />
            <Navbar dark expand="md" fixed="top" className="jh-navbar">
                <Brand />
                <Nav navbar>
                    <EntitiesMenu onClick={() => menuOpen && toggleMenu()} />
                </Nav>
                <NavbarToggler aria-label="Menu" onClick={toggleMenu}>
                    <FontAwesomeIcon icon="cog" />
                </NavbarToggler>
                <Collapse isOpen={menuOpen} navbar>
                    <Nav id="header-tabs" className="ml-auto" navbar>
                        <AccountMenu onClick={toggleMenu} />
                    </Nav>
                </Collapse>
            </Navbar>
        </div>
    );
}

import {FAILURE, REQUEST, SUCCESS} from "app/reducers/actionType.util";
import {cleanEntity} from "app/services/entity.service";
import axios from "axios";
import {IService} from "common/types";
import {
    ICrudDeleteAction,
    ICrudGetAction,
    ICrudGetAllAction,
    ICrudPutAction,
} from "react-jhipster";

export const ACTION_TYPES = {
    FETCH_SERVICE_LIST: "service/FETCH_SERVICE_LIST",
    FETCH_SERVICE: "service/FETCH_SERVICE",
    CREATE_SERVICE: "service/CREATE_SERVICE",
    UPDATE_SERVICE: "service/UPDATE_SERVICE",
    DELETE_SERVICE: "service/DELETE_SERVICE",
    RESET: "service/RESET",
};

const initialState = {
    loading: false,
    errorMessage: null,
    entities: [] as ReadonlyArray<IService>,
    entity: null as Readonly<IService> | null,
    updating: false,
    totalItems: 0,
    updateSuccess: false,
};

export type ServiceState = Readonly<typeof initialState>;

// Reducer

export default (state: ServiceState = initialState, action): ServiceState => {
    switch (action.type) {
        case REQUEST(ACTION_TYPES.FETCH_SERVICE_LIST):
        case REQUEST(ACTION_TYPES.FETCH_SERVICE):
            return {
                ...state,
                errorMessage: null,
                updateSuccess: false,
                loading: true,
            };
        case REQUEST(ACTION_TYPES.CREATE_SERVICE):
        case REQUEST(ACTION_TYPES.UPDATE_SERVICE):
        case REQUEST(ACTION_TYPES.DELETE_SERVICE):
            return {
                ...state,
                errorMessage: null,
                updateSuccess: false,
                updating: true,
            };
        case FAILURE(ACTION_TYPES.FETCH_SERVICE_LIST):
        case FAILURE(ACTION_TYPES.FETCH_SERVICE):
        case FAILURE(ACTION_TYPES.CREATE_SERVICE):
        case FAILURE(ACTION_TYPES.UPDATE_SERVICE):
        case FAILURE(ACTION_TYPES.DELETE_SERVICE):
            return {
                ...state,
                loading: false,
                updating: false,
                updateSuccess: false,
                errorMessage: action.payload,
            };
        case SUCCESS(ACTION_TYPES.FETCH_SERVICE_LIST):
            return {
                ...state,
                loading: false,
                entities: action.payload.data,
                totalItems: parseInt(action.payload.headers["x-total-count"], 10),
            };
        case SUCCESS(ACTION_TYPES.FETCH_SERVICE):
            return {
                ...state,
                loading: false,
                entity: action.payload.data,
            };
        case SUCCESS(ACTION_TYPES.CREATE_SERVICE):
        case SUCCESS(ACTION_TYPES.UPDATE_SERVICE):
            return {
                ...state,
                updating: false,
                updateSuccess: true,
                entity: action.payload.data,
            };
        case SUCCESS(ACTION_TYPES.DELETE_SERVICE):
            return {
                ...state,
                updating: false,
                updateSuccess: true,
                entity: null as Readonly<IService> | null,
            };
        case ACTION_TYPES.RESET:
            return {
                ...initialState,
            };
        default:
            return state;
    }
};

const apiUrl = "api/services";

// Actions

export const getEntities: ICrudGetAllAction<IService> = (page, size, sort) => {
    const requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}` : ""}`;
    return {
        type: ACTION_TYPES.FETCH_SERVICE_LIST,
        payload: axios.get<IService>(
            `${requestUrl}${sort ? "&" : "?"}cacheBuster=${new Date().getTime()}`
        ),
    };
};

export const getEntity: ICrudGetAction<IService> = (id) => {
    const requestUrl = `${apiUrl}/${id}`;
    return {
        type: ACTION_TYPES.FETCH_SERVICE,
        payload: axios.get<IService>(requestUrl),
    };
};

export const createEntity: ICrudPutAction<IService> = (entity) => async (dispatch) => {
    const result = await dispatch({
        type: ACTION_TYPES.CREATE_SERVICE,
        payload: axios.post(apiUrl, cleanEntity(entity)),
    });
    dispatch(getEntities());
    return result;
};

export const updateEntity: ICrudPutAction<IService> = (entity) => async (dispatch) => {
    const result = await dispatch({
        type: ACTION_TYPES.UPDATE_SERVICE,
        payload: axios.put(apiUrl, cleanEntity(entity)),
    });
    return result;
};

export const deleteEntity: ICrudDeleteAction<IService> = (id) => async (dispatch) => {
    const requestUrl = `${apiUrl}/${id}`;
    const result = await dispatch({
        type: ACTION_TYPES.DELETE_SERVICE,
        payload: axios.delete(requestUrl),
    });
    return result;
};

export const reset = () => ({
    type: ACTION_TYPES.RESET,
});

import {FAILURE, REQUEST, SUCCESS} from "app/reducers/actionType.util";
import axios from "axios";
import {IInvoiceFile} from "common/types";
import {ICrudGetAction} from "react-jhipster";

export const ACTION_TYPES = {
    FETCH_INVOICE_FILE: "invoiceFile/FETCH_INVOICE_FILE",
    RESET: "invoiceFile/RESET",
};

const initialState = {
    loading: false,
    errorMessage: null,
    file: null as Readonly<IInvoiceFile> | null,
};

export type InvoiceFileState = Readonly<typeof initialState>;

// Reducer

export default (state: InvoiceFileState = initialState, action): InvoiceFileState => {
    switch (action.type) {
        case REQUEST(ACTION_TYPES.FETCH_INVOICE_FILE):
            return {
                ...state,
                errorMessage: null,
                loading: true,
            };

        case FAILURE(ACTION_TYPES.FETCH_INVOICE_FILE):
            return {
                ...state,
                loading: false,
                errorMessage: action.payload,
            };

        case SUCCESS(ACTION_TYPES.FETCH_INVOICE_FILE):
            return {...state, errorMessage: null, loading: false, file: action.payload.data};

        case ACTION_TYPES.RESET:
            return {
                ...initialState,
            };
        default:
            return state;
    }
};

const apiUrl = "api/invoice-files";

// Actions

export const getInvoiceFile: ICrudGetAction<IInvoiceFile> = (id) => {
    const requestUrl = `${apiUrl}/${id}`;
    return {
        type: ACTION_TYPES.FETCH_INVOICE_FILE,
        payload: axios.get<IInvoiceFile>(requestUrl),
    };
};

export const reset = () => ({
    type: ACTION_TYPES.RESET,
});

import {FAILURE, REQUEST, SUCCESS} from "app/reducers/actionType.util";
import {cleanEntity} from "app/services/entity.service";
import axios from "axios";
import {IPlatformUser} from "common/types";
import {ICrudPutAction} from "react-jhipster";
import {IRootState} from "..";

export const ACTION_TYPES = {
    FETCH_PLATFORMUSER: "platformUser/FETCH_PLATFORMUSER",
    FETCH_PLATFORMUSER_SILENTLY: "platformUser/FETCH_PLATFORMUSER_SILENTLY",
    UPDATE_PLATFORMUSER: "platformUser/UPDATE_PLATFORMUSER",
    RESET: "platformUser/RESET",
};

const initialState = {
    loading: false,
    loaded: false,
    errorMessage: null,
    entity: null as Readonly<IPlatformUser> | null,
    updating: false,
    updateSuccess: false,
};

export type PlatformUserState = Readonly<typeof initialState>;

// Reducer

export default (state: PlatformUserState = initialState, action): PlatformUserState => {
    switch (action.type) {
        case REQUEST(ACTION_TYPES.FETCH_PLATFORMUSER):
            return {
                ...state,
                errorMessage: null,
                updateSuccess: false,
                loading: true,
            };
        case REQUEST(ACTION_TYPES.UPDATE_PLATFORMUSER):
            return {
                ...state,
                errorMessage: null,
                updateSuccess: false,
                updating: true,
            };
        case FAILURE(ACTION_TYPES.FETCH_PLATFORMUSER):
        case FAILURE(ACTION_TYPES.UPDATE_PLATFORMUSER):
            return {
                ...state,
                loading: false,
                loaded: true,
                updating: false,
                updateSuccess: false,
                errorMessage: action.payload,
            };
        case SUCCESS(ACTION_TYPES.FETCH_PLATFORMUSER_SILENTLY):
        case SUCCESS(ACTION_TYPES.FETCH_PLATFORMUSER):
            return {
                ...state,
                loading: false,
                loaded: true,
                entity: action.payload.data,
            };
        case SUCCESS(ACTION_TYPES.UPDATE_PLATFORMUSER):
            return {
                ...state,
                updating: false,
                updateSuccess: true,
                entity: action.payload.data,
            };
        case ACTION_TYPES.RESET:
            return {
                ...initialState,
            };
        default:
            return state;
    }
};

const apiUrl = "api/platform-users";

// Actions

export const loadCurrentEntity: () => void = () => async (dispatch, getState) => {
    const state: IRootState = getState();
    if (state.platformUser.loading || state.platformUser.loaded) {
        // Nada
    } else {
        await dispatch(getCurrentEntity());
    }
};

export const getCurrentEntity = (silently?: boolean) => {
    const requestUrl = `${apiUrl}/current`;
    return {
        type: silently
            ? ACTION_TYPES.FETCH_PLATFORMUSER_SILENTLY
            : ACTION_TYPES.FETCH_PLATFORMUSER,
        payload: axios.get<IPlatformUser>(requestUrl),
    };
};

export const updateEntity: ICrudPutAction<Partial<IPlatformUser>> =
    (entity) => async (dispatch) => {
        const result = await dispatch({
            type: ACTION_TYPES.UPDATE_PLATFORMUSER,
            payload: axios.put(apiUrl, cleanEntity(entity)),
        });
        return result;
    };

export const reset = () => ({
    type: ACTION_TYPES.RESET,
});

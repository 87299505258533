import {FAILURE, REQUEST, SUCCESS} from "app/reducers/actionType.util";
import axios from "axios";
import {IIncome, IInvoice, IncomeVAT} from "common/types";
import {IPayload} from "react-jhipster";

export const ACTION_TYPES = {
    FETCH_NEXT_INVOICES: "event/FETCH_NEXT_INVOICES",
    REFRESH_INVOICES: "event/REFRESH_INVOICES",
    FETCH_INCOMES: "invoice/FETCH_INCOMES",
    FETCH_INCOMES_VAT: "invoice/FETCH_INCOMES_VAT",
    RESET: "invoice/RESET",
};

const initialState = {
    loading: true,
    errorMessage: null,
    entities: [] as ReadonlyArray<IInvoice>,
    incomes: [] as ReadonlyArray<ReadonlyArray<IIncome>>,
    incomesVat: [] as ReadonlyArray<ReadonlyArray<IncomeVAT>>,
    totalItems: 0,
    nextPage: 0,
    loadingNext: false,
    loadingIncomes: false,
};

export type InvoiceState = Readonly<typeof initialState>;

// Reducer

export default (state: InvoiceState = initialState, action): InvoiceState => {
    switch (action.type) {
        case REQUEST(ACTION_TYPES.FETCH_INCOMES):
        case REQUEST(ACTION_TYPES.FETCH_INCOMES_VAT):
            return {
                ...state,
                errorMessage: null,
                loadingIncomes: true,
            };
        case REQUEST(ACTION_TYPES.FETCH_NEXT_INVOICES):
            return {
                ...state,
                errorMessage: null,
                loadingNext: true,
            };
        case REQUEST(ACTION_TYPES.REFRESH_INVOICES):
            return {
                ...state,
                errorMessage: null,
                loading: true,
                loadingNext: false,
            };
        case FAILURE(ACTION_TYPES.FETCH_NEXT_INVOICES):
        case FAILURE(ACTION_TYPES.FETCH_INCOMES):
        case FAILURE(ACTION_TYPES.FETCH_INCOMES_VAT):
        case FAILURE(ACTION_TYPES.REFRESH_INVOICES):
            return {
                ...state,
                loading: false,
                loadingNext: false,
                loadingIncomes: false,
                errorMessage: action.payload,
            };
        case SUCCESS(ACTION_TYPES.FETCH_NEXT_INVOICES):
            return {
                ...state,
                loading: false,
                loadingNext: false,
                nextPage: state.nextPage + 1,
                entities: [...state.entities, ...action.payload.data],
                totalItems: parseInt(action.payload.headers["x-total-count"], 10),
            };
        case SUCCESS(ACTION_TYPES.REFRESH_INVOICES):
            return {
                ...state,
                loading: false,
                loadingNext: false,
                nextPage: state.nextPage,
                entities: [...action.payload.data],
                totalItems: parseInt(action.payload.headers["x-total-count"], 10),
            };
        case SUCCESS(ACTION_TYPES.FETCH_INCOMES):
            return {
                ...state,
                loadingIncomes: false,
                incomes: action.payload.data,
            };
        case SUCCESS(ACTION_TYPES.FETCH_INCOMES_VAT):
            return {
                ...state,
                loadingIncomes: false,
                incomesVat: action.payload.data,
            };
        case ACTION_TYPES.RESET:
            return {
                ...initialState,
            };
        default:
            return state;
    }
};

const apiUrl = "api/invoices";

// Actions

export declare type ICrudGetAllAction2<T> = (
    page: number,
    size: number,
    sort?: string
) => IPayload<T> | ((dispatch: any) => IPayload<T>);

export declare type ICrudGetAllAction3<T> = (
    size?: number,
    sort?: string
) => IPayload<T> | ((dispatch: any) => IPayload<T>);

export const nextEntities: ICrudGetAllAction2<IInvoice> = (page, size, sort) => {
    const requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}` : ""}`;
    return {
        type: ACTION_TYPES.FETCH_NEXT_INVOICES,
        payload: axios.get<IInvoice>(
            `${requestUrl}${sort ? "&" : "?"}cacheBuster=${new Date().getTime()}`
        ),
    };
};

export const getIncomes: ICrudGetAllAction3<IIncome> = (size, sort) => {
    const requestUrl = `api/incomes${sort ? `?size=${size}&sort=${sort}` : ""}`;
    return {
        type: ACTION_TYPES.FETCH_INCOMES,
        payload: axios.get<IIncome>(
            `${requestUrl}${sort ? "&" : "?"}cacheBuster=${new Date().getTime()}`
        ),
    };
};

export const getIncomesVat: ICrudGetAllAction3<IncomeVAT> = (size, sort) => {
    const requestUrl = `api/incomes-vat${sort ? `?size=${size}&sort=${sort}` : ""}`;
    return {
        type: ACTION_TYPES.FETCH_INCOMES_VAT,
        payload: axios.get<IncomeVAT>(
            `${requestUrl}${sort ? "&" : "?"}cacheBuster=${new Date().getTime()}`
        ),
    };
};

export const refreshEntities: ICrudGetAllAction3<IInvoice> = (size, sort) => {
    const requestUrl = `${apiUrl}${sort ? `?page=0&size=${size}&sort=${sort}` : ""}`;
    return {
        type: ACTION_TYPES.REFRESH_INVOICES,
        payload: axios.get<IInvoice>(
            `${requestUrl}${sort ? "&" : "?"}cacheBuster=${new Date().getTime()}`
        ),
    };
};

export const reset = () => ({
    type: ACTION_TYPES.RESET,
});

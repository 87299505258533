import React, {useState} from "react";
import {useDispatch} from "react-redux";
import {useHistory} from "react-router-dom";
import {SplitterButtons} from "ui-kit/Splitter";
import {NoButton} from "ui-kit/form/button/NoButton";
import {ignoreOnboarding} from "../../../../reducers/user/onboarding";
import {ModalContent, ModalContentWrapper} from "../../ModalContent";
import {LetsGo} from "ui-kit/form/button/LetsGo";

export function EndingAssistant() {
    const history = useHistory();
    const [pending, setPending] = useState(false);
    const dispatch = useDispatch();

    return (
        <ModalContentWrapper>
            <ModalContent>
                <h2 style={{marginTop: "8px"}} className="title-custom-style">
                    {`C'est terminé !`}
                </h2>
                <p>
                    <p>{`Bravo 👏`}</p>
                    <p>{`Nous allons maintenant fermer l'assistant et créer un rendez-vous !`}</p>
                </p>
            </ModalContent>
            <SplitterButtons>
                <NoButton onClick={handleNo} disabled={pending} pending={pending} />
                <LetsGo onClick={handleLetsGo} disabled={pending} />
            </SplitterButtons>
        </ModalContentWrapper>
    );

    function handleNo() {
        dispatch(ignoreOnboarding());
    }

    function handleLetsGo() {
        dispatch(ignoreOnboarding());
        history.push(`event/new`);
    }
}

import {FAILURE, REQUEST, SUCCESS} from "app/reducers/actionType.util";
import {cleanEntity} from "app/services/entity.service";
import axios from "axios";
import {IServiceTarget} from "common/types";
import {
    ICrudDeleteAction,
    ICrudGetAction,
    ICrudGetAllAction,
    ICrudPutAction,
} from "react-jhipster";

export const ACTION_TYPES = {
    FETCH_SERVICETARGET_LIST: "serviceTarget/FETCH_SERVICETARGET_LIST",
    FETCH_SERVICETARGET_LIST2: "serviceTarget/FETCH_SERVICETARGET_LIST2",
    FETCH_SERVICETARGET: "serviceTarget/FETCH_SERVICETARGET",
    CREATE_SERVICETARGET: "serviceTarget/CREATE_SERVICETARGET",
    UPDATE_SERVICETARGET: "serviceTarget/UPDATE_SERVICETARGET",
    DELETE_SERVICETARGET: "serviceTarget/DELETE_SERVICETARGET",
    RESET: "serviceTarget/RESET",
};

const initialState = {
    loading: false,
    errorMessage: null,
    entities: [] as ReadonlyArray<IServiceTarget>,
    entities2: [] as ReadonlyArray<IServiceTarget>,
    entity: null as Readonly<IServiceTarget> | null,
    updating: false,
    totalItems: 0,
    updateSuccess: false,
};

export type ServiceTargetState = Readonly<typeof initialState>;

// Reducer

export default (state: ServiceTargetState = initialState, action): ServiceTargetState => {
    switch (action.type) {
        case REQUEST(ACTION_TYPES.FETCH_SERVICETARGET_LIST):
        case REQUEST(ACTION_TYPES.FETCH_SERVICETARGET_LIST2):
        case REQUEST(ACTION_TYPES.FETCH_SERVICETARGET):
            return {
                ...state,
                errorMessage: null,
                updateSuccess: false,
                loading: true,
            };
        case REQUEST(ACTION_TYPES.CREATE_SERVICETARGET):
        case REQUEST(ACTION_TYPES.UPDATE_SERVICETARGET):
        case REQUEST(ACTION_TYPES.DELETE_SERVICETARGET):
            return {
                ...state,
                errorMessage: null,
                updateSuccess: false,
                updating: true,
            };
        case FAILURE(ACTION_TYPES.FETCH_SERVICETARGET_LIST):
        case FAILURE(ACTION_TYPES.FETCH_SERVICETARGET_LIST2):
        case FAILURE(ACTION_TYPES.FETCH_SERVICETARGET):
        case FAILURE(ACTION_TYPES.CREATE_SERVICETARGET):
        case FAILURE(ACTION_TYPES.UPDATE_SERVICETARGET):
        case FAILURE(ACTION_TYPES.DELETE_SERVICETARGET):
            return {
                ...state,
                loading: false,
                updating: false,
                updateSuccess: false,
                errorMessage: action.payload,
            };
        case SUCCESS(ACTION_TYPES.FETCH_SERVICETARGET_LIST):
            return {
                ...state,
                loading: false,
                entities: action.payload.data,
                totalItems: parseInt(action.payload.headers["x-total-count"], 10),
            };
        case SUCCESS(ACTION_TYPES.FETCH_SERVICETARGET_LIST2):
            return {
                ...state,
                entities2: action.payload.data,
            };
        case SUCCESS(ACTION_TYPES.FETCH_SERVICETARGET):
            return {
                ...state,
                loading: false,
                entity: action.payload.data,
            };
        case SUCCESS(ACTION_TYPES.CREATE_SERVICETARGET):
        case SUCCESS(ACTION_TYPES.UPDATE_SERVICETARGET):
            return {
                ...state,
                updating: false,
                updateSuccess: true,
                entity: action.payload.data,
            };
        case SUCCESS(ACTION_TYPES.DELETE_SERVICETARGET):
            return {
                ...state,
                updating: false,
                updateSuccess: true,
                entity: null as Readonly<IServiceTarget> | null,
            };
        case ACTION_TYPES.RESET:
            return {
                ...initialState,
            };
        default:
            return state;
    }
};

const apiUrl = "api/service-targets";
const apiUrl2 = "api/custom/service-targets";

// Actions

export const getEntities: ICrudGetAllAction<IServiceTarget> = (page, size, sort) => {
    const requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}` : ""}`;
    return {
        type: ACTION_TYPES.FETCH_SERVICETARGET_LIST,
        payload: axios.get<IServiceTarget>(
            `${requestUrl}${sort ? "&" : "?"}cacheBuster=${new Date().getTime()}`
        ),
    };
};

export const getEntities2: ICrudGetAction<IServiceTarget[]> = (eventId) => {
    const requestUrl = `${apiUrl2}/all?eventId=${eventId}`;
    return {
        type: ACTION_TYPES.FETCH_SERVICETARGET_LIST2,
        payload: axios.get<IServiceTarget[]>(`${requestUrl}`),
    };
};

export const getEntity: ICrudGetAction<IServiceTarget> = (id) => {
    const requestUrl = `${apiUrl}/${id}`;
    return {
        type: ACTION_TYPES.FETCH_SERVICETARGET,
        payload: axios.get<IServiceTarget>(requestUrl),
    };
};

export const createEntity: ICrudPutAction<IServiceTarget> = (entity) => async (dispatch) => {
    const result = await dispatch({
        type: ACTION_TYPES.CREATE_SERVICETARGET,
        payload: axios.post(apiUrl, cleanEntity(entity)),
    });
    dispatch(getEntities());
    return result;
};

export const updateEntity: ICrudPutAction<IServiceTarget> = (entity) => async (dispatch) => {
    const result = await dispatch({
        type: ACTION_TYPES.UPDATE_SERVICETARGET,
        payload: axios.put(apiUrl, cleanEntity(entity)),
    });
    return result;
};

export const deleteEntity: ICrudDeleteAction<IServiceTarget> = (id) => async (dispatch) => {
    const requestUrl = `${apiUrl2}/${id}`;
    const result = await dispatch({
        type: ACTION_TYPES.DELETE_SERVICETARGET,
        payload: axios.delete(requestUrl),
    });
    return result;
};

export const reset = () => ({
    type: ACTION_TYPES.RESET,
});

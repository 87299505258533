import {FAILURE, REQUEST, SUCCESS} from "app/reducers/actionType.util";
import axios from "axios";
import {IIncome} from "common/types";
import {IPayload} from "react-jhipster";

export const ACTION_TYPES = {
    FETCH_INCOMES_BY_DATE: "income/FETCH_INCOMES_BY_DATE",
    RESET: "income/RESET",
};

const initialState = {
    loading: true,
    errorMessage: null,
    entities: [] as ReadonlyArray<IIncome>,
    totalItems: 0,
    nextPage: 0,
    loadingNext: false,
};

export type IncomeByDateState = Readonly<typeof initialState>;

// Reducer

export default (state: IncomeByDateState = initialState, action): IncomeByDateState => {
    switch (action.type) {
        case REQUEST(ACTION_TYPES.FETCH_INCOMES_BY_DATE):
            return {
                ...state,
                errorMessage: null,
                loading: true,
            };
        case FAILURE(ACTION_TYPES.FETCH_INCOMES_BY_DATE):
            return {
                ...state,
                loadingNext: false,
                loading: false,
                errorMessage: action.payload,
            };
        case SUCCESS(ACTION_TYPES.FETCH_INCOMES_BY_DATE):
            return {
                ...state,
                loading: false,
                entities: action.payload.data,
            };
        case ACTION_TYPES.RESET:
            return {
                ...initialState,
            };
        default:
            return state;
    }
};

const apiUrl = "api/invoices";

// Actions

export declare type ICrudGetAllAction<T> = (
    date: string,
    size?: number,
    sort?: string
) => IPayload<T> | ((dispatch: any) => IPayload<T>);

export const getIncomesByDate: ICrudGetAllAction<IIncome> = (date, size, sort) => {
    const requestUrl = `api/incomes/${date}/${sort ? `?size=${size}&sort=${sort}` : ""}`;
    return {
        type: ACTION_TYPES.FETCH_INCOMES_BY_DATE,
        payload: axios.get<IIncome>(
            `${requestUrl}${sort ? "&" : "?"}cacheBuster=${new Date().getTime()}`
        ),
    };
};

export const reset = () => ({
    type: ACTION_TYPES.RESET,
});

import {FAILURE, REQUEST, SUCCESS} from "app/reducers/actionType.util";
import axios from "axios";
import {IQuotationFile} from "common/types";
import {ICrudGetAction} from "react-jhipster";

export const ACTION_TYPES = {
    FETCH_INVOICE_FILE: "quotationFile/FETCH_INVOICE_FILE",
    RESET: "quotationFile/RESET",
};

const initialState = {
    loading: false,
    errorMessage: null,
    file: null as Readonly<IQuotationFile> | null,
};

export type QuotationFileState = Readonly<typeof initialState>;

// Reducer

export default (state: QuotationFileState = initialState, action): QuotationFileState => {
    switch (action.type) {
        case REQUEST(ACTION_TYPES.FETCH_INVOICE_FILE):
            return {
                ...state,
                errorMessage: null,
                loading: true,
            };

        case FAILURE(ACTION_TYPES.FETCH_INVOICE_FILE):
            return {
                ...state,
                loading: false,
                errorMessage: action.payload,
            };

        case SUCCESS(ACTION_TYPES.FETCH_INVOICE_FILE):
            return {...state, errorMessage: null, loading: false, file: action.payload.data};

        case ACTION_TYPES.RESET:
            return {
                ...initialState,
            };
        default:
            return state;
    }
};

const apiUrl = "api/quotation-files";

// Actions

export const getQuotationFile: ICrudGetAction<IQuotationFile> = (id) => {
    const requestUrl = `${apiUrl}/${id}`;
    return {
        type: ACTION_TYPES.FETCH_INVOICE_FILE,
        payload: axios.get<IQuotationFile>(requestUrl),
    };
};

export const reset = () => ({
    type: ACTION_TYPES.RESET,
});

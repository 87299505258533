import {FAILURE, REQUEST, SUCCESS} from "app/reducers/actionType.util";
import axios from "axios";
import {AgendaEvent} from "common/types";

export const ACTION_TYPES = {
    FETCH_EVENTS: "agendaEvent/FETCH_EVENTS",
    RESET: "agendaEvent/RESET",
};

const initialState = {
    loading: true,
    errorMessage: null,
    entities: [] as ReadonlyArray<AgendaEvent>,
    updateSuccess: false,
};

export type AgendaEventState = Readonly<typeof initialState>;

// Reducer

export default (state: AgendaEventState = initialState, action): AgendaEventState => {
    const existingIds = state.entities.map((e) => e.id);
    switch (action.type) {
        case REQUEST(ACTION_TYPES.FETCH_EVENTS):
            return {
                ...state,
                loading: true,
                updateSuccess: false,
                errorMessage: null,
            };
        case FAILURE(ACTION_TYPES.FETCH_EVENTS):
            return {
                ...state,
                loading: false,
                updateSuccess: false,
                errorMessage: action.payload,
            };
        case SUCCESS(ACTION_TYPES.FETCH_EVENTS):
            return {
                ...state,
                loading: false,
                entities: [
                    ...state.entities,
                    ...action.payload.data.filter((e) => !existingIds.includes(e.id)),
                ],
            };
        case ACTION_TYPES.RESET:
            return {
                ...initialState,
            };
        default:
            return state;
    }
};

const apiUrl = "api/events";

// Actions

export function fetchEvents(start: string, end: string) {
    const requestUrl = `${apiUrl}/v3/?start=${start}&end=${end}`;
    return {
        type: ACTION_TYPES.FETCH_EVENTS,
        payload: axios.get<AgendaEvent[]>(`${requestUrl}&cacheBuster=${new Date().getTime()}`),
    };
}

export const reset = () => ({
    type: ACTION_TYPES.RESET,
});

import {FAILURE, REQUEST, SUCCESS} from "app/reducers/actionType.util";
import {cleanEntity} from "app/services/entity.service";
import axios from "axios";
import {ICustomer} from "common/types";
import {
    ICrudDeleteAction,
    ICrudGetAction,
    ICrudGetAllAction,
    ICrudPutAction,
    IPayload,
} from "react-jhipster";

export const ACTION_TYPES = {
    FETCH_CUSTOMERS: "customer/FETCH_CUSTOMERS",
    FETCH_NEXT_CUSTOMERS: "customer/FETCH_NEXT_CUSTOMERS",
    REFRESH_CUSTOMERS: "customer/REFRESH_CUSTOMERS",
    FETCH_CUSTOMER: "customer/FETCH_CUSTOMER",
    CREATE_CUSTOMER: "customer/CREATE_CUSTOMER",
    UPDATE_CUSTOMER: "customer/UPDATE_CUSTOMER",
    DELETE_CUSTOMER: "customer/DELETE_CUSTOMER",
    RESET: "customer/RESET",
};

const initialState = {
    loading: true,
    loadingNext: false,
    errorMessage: null,
    entities: [] as ReadonlyArray<ICustomer>,
    entity: null as Readonly<ICustomer> | null,
    updating: false,
    totalItems: 0,
    nextPage: 0,
    updateSuccess: false,
};

export type CustomerState = Readonly<typeof initialState>;

// Reducer

export default (state: CustomerState = initialState, action): CustomerState => {
    switch (action.type) {
        case REQUEST(ACTION_TYPES.FETCH_CUSTOMERS):
        case REQUEST(ACTION_TYPES.FETCH_CUSTOMER):
            return {
                ...state,
                errorMessage: null,
                updateSuccess: false,
                loading: true,
                loadingNext: false,
            };
        case REQUEST(ACTION_TYPES.FETCH_NEXT_CUSTOMERS):
            return {
                ...state,
                errorMessage: null,
                updateSuccess: false,
                loadingNext: true,
            };
        case REQUEST(ACTION_TYPES.REFRESH_CUSTOMERS):
            return {
                ...state,
                errorMessage: null,
                updateSuccess: false,
                loading: true,
                loadingNext: false,
            };
        case REQUEST(ACTION_TYPES.CREATE_CUSTOMER):
        case REQUEST(ACTION_TYPES.UPDATE_CUSTOMER):
        case REQUEST(ACTION_TYPES.DELETE_CUSTOMER):
        case FAILURE(ACTION_TYPES.FETCH_NEXT_CUSTOMERS):
        case FAILURE(ACTION_TYPES.FETCH_CUSTOMERS):
        case FAILURE(ACTION_TYPES.REFRESH_CUSTOMERS):
        case FAILURE(ACTION_TYPES.FETCH_CUSTOMER):
        case FAILURE(ACTION_TYPES.CREATE_CUSTOMER):
        case FAILURE(ACTION_TYPES.UPDATE_CUSTOMER):
        case FAILURE(ACTION_TYPES.DELETE_CUSTOMER):
            return {
                ...state,
                loading: false,
                loadingNext: false,
                updating: false,
                updateSuccess: false,
                errorMessage: action.payload,
            };

        case SUCCESS(ACTION_TYPES.FETCH_CUSTOMERS):
            return {
                ...state,
                loading: false,
                entities: action.payload.data,
                totalItems: parseInt(action.payload.headers["x-total-count"], 10),
            };

        case SUCCESS(ACTION_TYPES.FETCH_NEXT_CUSTOMERS):
            return {
                ...state,
                loading: false,
                loadingNext: false,
                nextPage: state.nextPage + 1,
                entities: [...state.entities, ...action.payload.data],
                totalItems: parseInt(action.payload.headers["x-total-count"], 10),
            };
        case SUCCESS(ACTION_TYPES.REFRESH_CUSTOMERS):
            return {
                ...state,
                loading: false,
                loadingNext: false,
                nextPage: state.nextPage,
                entities: [...action.payload.data],
                totalItems: parseInt(action.payload.headers["x-total-count"], 10),
            };
        case SUCCESS(ACTION_TYPES.FETCH_CUSTOMER):
            return {
                ...state,
                loading: false,
                loadingNext: false,
                entity: action.payload.data,
            };
        case SUCCESS(ACTION_TYPES.CREATE_CUSTOMER):
        case SUCCESS(ACTION_TYPES.UPDATE_CUSTOMER):
            return {
                ...state,
                updating: false,
                updateSuccess: true,
                entity: action.payload.data,
            };
        case SUCCESS(ACTION_TYPES.DELETE_CUSTOMER):
            return {
                ...state,
                updating: false,
                updateSuccess: true,
                entity: null as Readonly<ICustomer> | null,
                entities: [...state.entities].filter((entity) => entity.id !== state?.entity?.id),
            };
        case ACTION_TYPES.RESET:
            return {
                ...initialState,
                loading: false,
            };
        default:
            return state;
    }
};

const apiUrl = "api/customers";

// Actions

export declare type ICrudGetAllAction2<T> = (
    filter: string,
    page: number,
    size: number,
    sort?: string
) => IPayload<T> | ((dispatch: any) => IPayload<T>);

export declare type ICrudGetAllAction3<T> = (
    filter: string,
    size?: number,
    sort?: string
) => IPayload<T> | ((dispatch: any) => IPayload<T>);

export const getEntities: ICrudGetAllAction<ICustomer[]> = (page, size, sort) => {
    const requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}` : ""}`;
    return {
        type: ACTION_TYPES.FETCH_CUSTOMERS,
        payload: axios.get<ICustomer[]>(
            `${requestUrl}${sort ? "&" : "?"}cacheBuster=${new Date().getTime()}`
        ),
    };
};

export const nextEntities: ICrudGetAllAction2<ICustomer> = (filter, page, size, sort) => {
    const requestUrl = `${apiUrl}${
        sort ? `?filter=${filter}&page=${page}&size=${size}&sort=${sort}` : ""
    }`;
    return {
        type: ACTION_TYPES.FETCH_NEXT_CUSTOMERS,
        payload: axios.get<ICustomer>(
            `${requestUrl}${sort ? "&" : "?"}cacheBuster=${new Date().getTime()}`
        ),
    };
};

export const refreshEntities: ICrudGetAllAction3<ICustomer> = (filter, size, sort) => {
    const requestUrl = `${apiUrl}${
        sort ? `?filter=${filter}&page=0&size=${size}&sort=${sort}` : ""
    }`;
    return {
        type: ACTION_TYPES.REFRESH_CUSTOMERS,
        payload: axios.get<ICustomer>(
            `${requestUrl}${sort ? "&" : "?"}cacheBuster=${new Date().getTime()}`
        ),
    };
};

export const getEntity: ICrudGetAction<ICustomer> = (id) => {
    const requestUrl = `${apiUrl}/${id}`;
    return {
        type: ACTION_TYPES.FETCH_CUSTOMER,
        payload: axios.get<ICustomer>(requestUrl),
    };
};

export const createEntity: ICrudPutAction<ICustomer> = (entity) => async (dispatch) => {
    const result = await dispatch({
        type: ACTION_TYPES.CREATE_CUSTOMER,
        payload: axios.post(apiUrl, cleanEntity(entity)),
    });
    return result;
};

export const updateEntity: ICrudPutAction<ICustomer> = (entity) => async (dispatch) => {
    const result = await dispatch({
        type: ACTION_TYPES.UPDATE_CUSTOMER,
        payload: axios.put(apiUrl, cleanEntity(entity)),
    });
    return result;
};

export const deleteEntity: ICrudDeleteAction<ICustomer> = (id) => async (dispatch) => {
    const requestUrl = `${apiUrl}/${id}`;
    const result = await dispatch({
        type: ACTION_TYPES.DELETE_CUSTOMER,
        payload: axios.delete(requestUrl),
    });
    return result;
};

export const reset = () => ({
    type: ACTION_TYPES.RESET,
});

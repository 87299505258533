import {IRootState} from "app/reducers";
import {invitation} from "app/reducers/user/authentication";
import React, {useEffect} from "react";
import ReactGA from "react-ga4";
import {useDispatch, useSelector} from "react-redux";
import {Redirect, useLocation, useParams} from "react-router-dom";
import {Loading} from "ui-kit/Loading";
function useQuery() {
    const {search} = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
}

const InvitationPage = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const {invitationKey} = useParams<{invitationKey: string}>();
    const query = useQuery();
    const owner = query.get("owner");

    const {authentication} = useSelector(({authentication}: IRootState) => ({
        authentication,
    }));
    const {invitationError, errorMessage, invitationSuccess} = authentication;

    useEffect(() => {
        if (invitationKey && owner) {
            ReactGA.event("login", {method: "invitation"});
            dispatch(invitation(invitationKey, owner));
        }
    }, []);
    if (invitationSuccess) {
        const {from} = (location.state as any) || {from: {pathname: "/", search: location.search}};
        return <Redirect to={from} />;
    } else if (invitationError) {
        return <>{errorMessage}</>;
    }

    return <Loading />;
};
export default InvitationPage;

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React from "react";
import {Trans as Translate} from "react-i18next";
import {NavLink as Link} from "react-router-dom";
import {NavItem, NavLink} from "reactstrap";

export const EntitiesMenu = (props) => (
    <>
        <NavItem>
            <NavLink
                tag={Link}
                to="/event"
                id="events-nav"
                className="d-flex align-items-center"
                onClick={props.onClick}
            >
                <FontAwesomeIcon icon="calendar-alt" />
                <span className="d-none d-lg-block">
                    <Translate i18nKey="global.menu.home">Home</Translate>
                </span>
            </NavLink>
        </NavItem>
        <NavItem>
            <NavLink
                tag={Link}
                to="/customer"
                id="customers-nav"
                className="d-flex align-items-center"
                onClick={props.onClick}
            >
                <FontAwesomeIcon icon="users" />

                <span className="d-none d-lg-block">
                    <Translate i18nKey="global.menu.entities.customer" />
                </span>
            </NavLink>
        </NavItem>
        <NavItem>
            <NavLink
                tag={Link}
                to="/invoice"
                id="invoices-nav"
                className="d-flex align-items-center"
                onClick={props.onClick}
            >
                <FontAwesomeIcon icon="file-invoice-dollar" />

                <span className="d-none d-lg-block">
                    <Translate i18nKey="global.menu.entities.invoice" />
                </span>
            </NavLink>
        </NavItem>
    </>
);

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React from "react";
import {Button} from "reactstrap";

type Props = {
    onClick: () => void;
    disabled: boolean;
};

export function BackButton({onClick, disabled}: Props) {
    return (
        <Button
            id="backButton"
            type="button"
            color="secondary"
            onClick={onClick}
            disabled={disabled}
        >
            <FontAwesomeIcon icon="arrow-left" />
            &nbsp;
            <span>Retour</span>
        </Button>
    );
}

import {IconProp} from "@fortawesome/fontawesome-svg-core";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React, {FunctionComponent, ReactNode} from "react";
import {NavLink as Link} from "react-router-dom";
import {DropdownItem} from "reactstrap";

export interface IMenuItem {
    icon: IconProp;
    to: string;
    id?: string;
    onClick?: React.MouseEventHandler;
    "data-cy"?: string;
    children: ReactNode;
}

export const MenuItem: FunctionComponent<IMenuItem> = (props) => {
    const {to, icon, id, children, onClick, "data-cy": string} = props;

    return (
        <DropdownItem tag={Link} to={to} id={id} onClick={onClick} data-cy={props["data-cy"]}>
            <FontAwesomeIcon icon={icon} fixedWidth /> {children}
        </DropdownItem>
    );
};

import React from "react";

export function Step1() {
    return (
        <>
            <h2 style={{marginTop: "8px", marginBottom: "16px"}} className="title-custom-style">
                {`Les rendez-vous`}
            </h2>
            <p>{`Les rendez-vous sont sur cette page :`}</p>
            <img src={`images/help/rdv.png`} style={{border: "1px solid #4777"}} />
            <p style={{marginTop: "16px"}}>
                {`Vous ne trouverez que des rendez-vous `}
                <b>{`non finalisés`}</b>
                {`.`}
            </p>
            <p>
                <i>{`Non finalisé -> pas de facture réglée`}</i>
            </p>
            <div className={`alert alert-info`}>
                <p>{`Le bouton + permet de créer un nouveau rendez-vous`}</p>
            </div>
        </>
    );
}

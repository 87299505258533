import React, {useState} from "react";

import {useDispatch} from "react-redux";
import {IPlatformUser} from "common/types";
import {getSession, loadSession} from "../../reducers/user/authentication";
import {loadCurrentEntity} from "../../reducers/user/platformUser.reducer";
import {PaymentDataModal} from "./payment/PaymentDataModal";
import {RegisterModal} from "./payment/RegisterModal";
import {RevolutModal} from "./payment/RevolutModal";
export function PaymentModal({platformUser}: {platformUser: IPlatformUser}) {
    const dispatch = useDispatch();
    dispatch(loadCurrentEntity());
    dispatch(loadSession());

    const [type, setType] = useState<"monthly" | "yearly">("monthly");
    const [step, setStep] = useState<
        "endOfTrial" | "paymentData" | "fixRecurringPayment" | "payment"
    >("endOfTrial");

    if (step === "endOfTrial") {
        return (
            <RegisterModal
                platformUser={platformUser}
                onRegister={(type: "monthly" | "yearly") => {
                    setType(type);
                    setStep("paymentData");
                }}
                onFixRecurringPayment={(type: "monthly" | "yearly") => {
                    setType(type);
                    setStep("fixRecurringPayment");
                }}
            />
        );
    }
    if (step === "paymentData") {
        return (
            <PaymentDataModal
                onAbort={() => setStep("endOfTrial")}
                onValidate={() => setStep("payment")}
            />
        );
    }
    if (step === "payment") {
        return (
            <RevolutModal
                type={type}
                onAbort={() => setStep("paymentData")}
                onPay={() => dispatch(getSession())}
            />
        );
    }
    if (step === "fixRecurringPayment") {
        return (
            <RevolutModal
                type={type}
                onAbort={() => setStep("endOfTrial")}
                onPay={() => dispatch(getSession())}
            />
        );
    }

    return null; // Invalid case
}

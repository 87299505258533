import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import axios from "axios";
import React, {useState} from "react";
import {useDispatch} from "react-redux";
import {toast} from "react-toastify";
import {Button} from "reactstrap";
import {IAccount} from "common/types";
import {getSession} from "../../../../../reducers/user/authentication";
type Props = {account: IAccount};
export function Step1({account}: Props) {
    const dispatch = useDispatch();
    const [isRevoking, setRevoke] = useState<boolean>(false);
    if (account.userinfo) {
        return (
            <div>
                <p>
                    <FontAwesomeIcon icon="check" />{" "}
                    <strong>{`Compte Google Agenda trouvé`}</strong>
                </p>
                <p style={{marginLeft: "8px"}}>
                    <img width="20px" src={account.userinfo.picture} /> {account.userinfo.email}
                </p>
                {!account.hasGoogleCalendar && (
                    <div className={`alert alert-warning`}>
                        <p>
                            <strong>{`MyTour ne peut pas synchroniser l'agenda google`}</strong>
                            <br />
                            {`Vous n'avez pas donné l'autorisation au moment de la connexion avec Google.`}
                            <br />
                            {`Pour activer cette fonctionnalité, vous pouvez cliquer sur`}{" "}
                            <strong>{`réinitialiser`}</strong>
                            {`.`}
                        </p>
                    </div>
                )}

                <Button
                    color="primary"
                    type="submit"
                    disabled={isRevoking}
                    onClick={() => {
                        setRevoke(true);
                        axios
                            .get(`api/google/revoke`)
                            .then(() => {
                                dispatch(getSession());
                            })
                            .catch((e) => {
                                toast.error(e.message);
                            })
                            .finally(() => {
                                setRevoke(false);
                            });
                    }}
                >
                    Réinitialiser
                </Button>
            </div>
        );
    } else {
        return (
            <div>
                <p>
                    <strong>Sélectionner un compte Google</strong>
                </p>
                <p>Pour activer la synchronisation, vous devez choisir un compte Google.</p>
            </div>
        );
    }
}

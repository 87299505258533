import {FAILURE, REQUEST, SUCCESS} from "app/reducers/actionType.util";
import axios from "axios";
import {IAccount} from "common/types";
import {Storage} from "react-jhipster";
import {IRootState} from "..";

export const ACTION_TYPES = {
    LOGIN: "authentication/LOGIN",
    MODERATION: "authentication/MODERATION",
    ONBOARDING: "authentication/ONBOARDING",
    INVITATION: "authentication/INVITATION",
    GET_SESSION: "authentication/GET_SESSION",
    LOGOUT: "authentication/LOGOUT",
    CLEAR_AUTH: "authentication/CLEAR_AUTH",
    ERROR_MESSAGE: "authentication/ERROR_MESSAGE",
};

const AUTH_TOKEN_KEY = "jhi-authenticationToken";
const AUTH_MODERATION_KEY = "jhi-moderation";

const initialState = {
    loading: false,
    loaded: false,
    isAuthenticated: false,
    isModeration: false,
    invitationSuccess: false,
    invitationError: false,
    onboardingSuccess: false,
    onboardingError: false,
    loginSuccess: false,
    loginError: false, // Errors returned from server side
    showModalLogin: false,
    account: null as IAccount | null,
    errorMessage: null as unknown as string, // Errors returned from server side
    redirectMessage: null as unknown as string,
    sessionHasBeenFetched: false,
    idToken: null as unknown as string,
    logoutUrl: null as unknown as string,
};

export type AuthenticationState = Readonly<typeof initialState>;

// Reducer

export default (state: AuthenticationState = initialState, action): AuthenticationState => {
    switch (action.type) {
        case REQUEST(ACTION_TYPES.LOGIN):
        case REQUEST(ACTION_TYPES.INVITATION):
        case REQUEST(ACTION_TYPES.GET_SESSION):
            return {
                ...state,
                loading: true,
            };
        case FAILURE(ACTION_TYPES.LOGIN):
            return {
                ...initialState,
                errorMessage: action.payload,
                showModalLogin: true,
                loginError: true,
            };
        case FAILURE(ACTION_TYPES.INVITATION):
            return {
                ...initialState,
                errorMessage: action.payload,
                showModalLogin: true,
                invitationError: true,
            };
        case FAILURE(ACTION_TYPES.ONBOARDING):
            return {
                ...initialState,
                errorMessage: action.payload,
                showModalLogin: true,
                onboardingError: true,
            };
        case FAILURE(ACTION_TYPES.GET_SESSION):
            return {
                ...state,
                loading: false,
                isAuthenticated: false,
                sessionHasBeenFetched: true,
                showModalLogin: true,
                errorMessage: action.payload,
            };
        case SUCCESS(ACTION_TYPES.INVITATION):
            return {
                ...state,
                loaded: true,
                loading: false,
                loginError: false,
                showModalLogin: false,
                invitationSuccess: true,
                loginSuccess: true,
            };
        case SUCCESS(ACTION_TYPES.ONBOARDING):
            return {
                ...state,
                loaded: true,
                loading: false,
                loginError: false,
                showModalLogin: false,
                onboardingSuccess: true,
                loginSuccess: true,
            };
        case SUCCESS(ACTION_TYPES.LOGIN):
            return {
                ...state,
                loaded: true,
                loading: false,
                loginError: false,
                showModalLogin: false,
                loginSuccess: true,
            };
        case ACTION_TYPES.LOGOUT:
            return {
                ...initialState,
                sessionHasBeenFetched: true,
                showModalLogin: true,
            };
        case ACTION_TYPES.MODERATION:
            return {
                ...state,
                isModeration: action.payload,
            };
        case SUCCESS(ACTION_TYPES.GET_SESSION): {
            const isAuthenticated =
                action.payload && action.payload.data && action.payload.data.activated;
            return {
                ...state,
                loaded: true,
                isAuthenticated,
                loading: false,
                sessionHasBeenFetched: true,
                account: action.payload.data,
            };
        }
        case ACTION_TYPES.ERROR_MESSAGE:
            return {
                ...initialState,
                showModalLogin: true,
                redirectMessage: action.message,
            };
        case ACTION_TYPES.CLEAR_AUTH:
            return {
                ...state,
                loading: false,
                showModalLogin: true,
                isAuthenticated: false,
            };
        default:
            return state;
    }
};

export const displayAuthError = (message) => ({type: ACTION_TYPES.ERROR_MESSAGE, message});

export const loadSession: () => void = () => async (dispatch, getState) => {
    const state: IRootState = getState();

    if (state.authentication.loading || state.authentication.loaded) {
        // Nada
    } else {
        await dispatch(getSession());
    }
};

export const getSession: () => void = () => async (dispatch, getState) => {
    await dispatch({
        type: ACTION_TYPES.MODERATION,
        payload: !!Storage.local.get(AUTH_MODERATION_KEY),
    });

    if (Storage.local.get(AUTH_TOKEN_KEY) || Storage.session.get(AUTH_TOKEN_KEY)) {
        await dispatch({
            type: ACTION_TYPES.GET_SESSION,
            payload: axios.get("api/account"),
        });
    } else {
        await dispatch({
            type: ACTION_TYPES.LOGOUT,
        });
    }
};

export const login: (username: string, password: string, rememberMe: boolean) => void =
    (username, password, rememberMe) => async (dispatch, getState) => {
        try {
            const result = await dispatch({
                type: ACTION_TYPES.LOGIN,
                payload: axios.post("api/authenticate", {username, password, rememberMe}),
            });
            const bearerToken = result.value.headers.authorization;
            if (bearerToken && bearerToken.slice(0, 7) === "Bearer ") {
                const jwt = bearerToken.slice(7, bearerToken.length);
                Storage.local.set(AUTH_TOKEN_KEY, jwt);
                Storage.local.set(AUTH_MODERATION_KEY, false);
            }
            await dispatch(getSession());
        } catch {
            // Nada
        }
    };

export const invitation: (invitationKey: string, invitationOwner: string) => void =
    (invitationKey, invitationOwner) => async (dispatch) => {
        clearAuthToken();
        await dispatch({
            type: ACTION_TYPES.LOGOUT,
        });
        const result = await dispatch({
            type: ACTION_TYPES.INVITATION,
            payload: axios.post("api/authenticate", {invitationKey, invitationOwner}),
        });
        const bearerToken = result.value.headers.authorization;
        if (bearerToken && bearerToken.slice(0, 7) === "Bearer ") {
            const jwt = bearerToken.slice(7, bearerToken.length);
            Storage.local.set(AUTH_TOKEN_KEY, jwt);
            Storage.local.set(AUTH_MODERATION_KEY, true);
        }
        await dispatch(getSession());
    };

export const onboarding: (invitationKey: string) => void = (invitationKey) => async (dispatch) => {
    clearAuthToken();
    await dispatch({
        type: ACTION_TYPES.LOGOUT,
    });
    const result = await dispatch({
        type: ACTION_TYPES.INVITATION,
        payload: axios.post("api/authenticate", {invitationKey}),
    });
    const bearerToken = result.value.headers.authorization;
    if (bearerToken && bearerToken.slice(0, 7) === "Bearer ") {
        const jwt = bearerToken.slice(7, bearerToken.length);
        Storage.local.set(AUTH_TOKEN_KEY, jwt);
        Storage.local.set(AUTH_MODERATION_KEY, false);
    }
    await dispatch(getSession());
};

export const clearAuthToken = () => {
    if (Storage.local.get(AUTH_TOKEN_KEY)) {
        Storage.local.remove(AUTH_TOKEN_KEY);
    }
    if (Storage.session.get(AUTH_TOKEN_KEY)) {
        Storage.session.remove(AUTH_TOKEN_KEY);
    }
    Storage.local.remove(AUTH_MODERATION_KEY);
};

export const logout: () => void = () => (dispatch) => {
    clearAuthToken();
    dispatch({
        type: ACTION_TYPES.LOGOUT,
    });
};

export const clearAuthentication = (messageKey) => (dispatch, getState) => {
    clearAuthToken();
    dispatch(displayAuthError(messageKey));
    dispatch({
        type: ACTION_TYPES.CLEAR_AUTH,
    });
};

import React from "react";
import {Button} from "reactstrap";

type Props = {
    onClick: () => void;
    disabled: boolean;
};

export function LetsGo({onClick, disabled}: Props) {
    return (
        <Button
            id="nextButton"
            type="button"
            color="success"
            onClick={onClick}
            disabled={disabled}
        >
            Allons-y !
        </Button>
    );
}

import {FAILURE, REQUEST, SUCCESS} from "app/reducers/actionType.util";
import {cleanEntity} from "app/services/entity.service";
import axios from "axios";
import {IServiceDescription} from "common/types";
import {ICrudDeleteAction, ICrudGetAction, ICrudPutAction, IPayload} from "react-jhipster";

export const ACTION_TYPES = {
    FETCH_NEXT_SERVICEDESCRIPTIONS: "serviceDescription/FETCH_NEXT_SERVICEDESCRIPTIONS",
    FETCH_SERVICEDESCRIPTION: "serviceDescription/FETCH_SERVICEDESCRIPTION",
    REFRESH_SERVICEDESCRIPTIONS: "serviceDescription/REFRESH_SERVICEDESCRIPTIONS",
    CREATE_SERVICEDESCRIPTION: "serviceDescription/CREATE_SERVICEDESCRIPTION",
    UPDATE_SERVICEDESCRIPTION: "serviceDescription/UPDATE_SERVICEDESCRIPTION",
    DELETE_SERVICEDESCRIPTION: "serviceDescription/DELETE_SERVICEDESCRIPTION",
    RESET: "serviceDescription/RESET",
};

const initialState = {
    loading: true,
    loadingNext: false,
    errorMessage: null,
    entities: [] as ReadonlyArray<IServiceDescription>,
    entity: null as Readonly<IServiceDescription> | null,
    updating: false,
    totalItems: 0,
    nextPage: 0,
    updateSuccess: false,
};

export type ServiceDescriptionState = Readonly<typeof initialState>;

// Reducer

export default (
    state: ServiceDescriptionState = initialState,
    action
): ServiceDescriptionState => {
    switch (action.type) {
        case REQUEST(ACTION_TYPES.FETCH_SERVICEDESCRIPTION):
            return {
                ...state,
                errorMessage: null,
                updateSuccess: false,
                loading: true,
                loadingNext: false,
            };
        case REQUEST(ACTION_TYPES.FETCH_NEXT_SERVICEDESCRIPTIONS):
            return {
                ...state,
                errorMessage: null,
                updateSuccess: false,
                loadingNext: true,
            };
        case REQUEST(ACTION_TYPES.REFRESH_SERVICEDESCRIPTIONS):
            return {
                ...state,
                errorMessage: null,
                updateSuccess: false,
                loading: true,
                loadingNext: false,
            };
        case REQUEST(ACTION_TYPES.CREATE_SERVICEDESCRIPTION):
        case REQUEST(ACTION_TYPES.UPDATE_SERVICEDESCRIPTION):
        case REQUEST(ACTION_TYPES.DELETE_SERVICEDESCRIPTION):
        case FAILURE(ACTION_TYPES.FETCH_NEXT_SERVICEDESCRIPTIONS):
        case FAILURE(ACTION_TYPES.REFRESH_SERVICEDESCRIPTIONS):
        case FAILURE(ACTION_TYPES.FETCH_SERVICEDESCRIPTION):
        case FAILURE(ACTION_TYPES.CREATE_SERVICEDESCRIPTION):
        case FAILURE(ACTION_TYPES.UPDATE_SERVICEDESCRIPTION):
        case FAILURE(ACTION_TYPES.DELETE_SERVICEDESCRIPTION):
            return {
                ...state,
                loading: false,
                loadingNext: false,
                updating: false,
                updateSuccess: false,
                errorMessage: action.payload,
            };
        case SUCCESS(ACTION_TYPES.FETCH_NEXT_SERVICEDESCRIPTIONS):
            return {
                ...state,
                loading: false,
                loadingNext: false,
                nextPage: state.nextPage + 1,
                entities: [...state.entities, ...action.payload.data],
                totalItems: parseInt(action.payload.headers["x-total-count"], 10),
            };
        case SUCCESS(ACTION_TYPES.REFRESH_SERVICEDESCRIPTIONS):
            return {
                ...state,
                loading: false,
                loadingNext: false,
                nextPage: state.nextPage,
                entities: [...action.payload.data],
                totalItems: parseInt(action.payload.headers["x-total-count"], 10),
            };
        case SUCCESS(ACTION_TYPES.FETCH_SERVICEDESCRIPTION):
            return {
                ...state,
                loading: false,
                loadingNext: false,
                entity: action.payload.data,
            };
        case SUCCESS(ACTION_TYPES.CREATE_SERVICEDESCRIPTION):
        case SUCCESS(ACTION_TYPES.UPDATE_SERVICEDESCRIPTION):
            return {
                ...state,
                updating: false,
                updateSuccess: true,
                entity: action.payload.data,
            };
        case SUCCESS(ACTION_TYPES.DELETE_SERVICEDESCRIPTION):
            return {
                ...state,
                updating: false,
                updateSuccess: true,
                entity: null as Readonly<IServiceDescription> | null,
            };
        case ACTION_TYPES.RESET:
            return {
                ...initialState,
                loading: false,
            };
        default:
            return state;
    }
};

const apiUrl = "api/service-descriptions";

// Actions

export declare type ICrudGetAllAction2<T> = (
    page: number,
    size: number,
    sort?: string
) => IPayload<T> | ((dispatch: any) => IPayload<T>);

export declare type ICrudGetAllAction3<T> = (
    size?: number,
    sort?: string
) => IPayload<T> | ((dispatch: any) => IPayload<T>);

export const nextEntities: ICrudGetAllAction2<IServiceDescription> = (page, size, sort) => {
    const requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}` : ""}`;
    return {
        type: ACTION_TYPES.FETCH_NEXT_SERVICEDESCRIPTIONS,
        payload: axios.get<IServiceDescription>(
            `${requestUrl}${sort ? "&" : "?"}cacheBuster=${new Date().getTime()}`
        ),
    };
};

export const refreshEntities: ICrudGetAllAction3<IServiceDescription> = (size, sort) => {
    const requestUrl = `${apiUrl}${sort ? `?page=0&size=${size}&sort=${sort}` : ""}`;
    return {
        type: ACTION_TYPES.REFRESH_SERVICEDESCRIPTIONS,
        payload: axios.get<IServiceDescription>(
            `${requestUrl}${sort ? "&" : "?"}cacheBuster=${new Date().getTime()}`
        ),
    };
};

export const getEntity: ICrudGetAction<IServiceDescription> = (id) => {
    const requestUrl = `${apiUrl}/${id}`;
    return {
        type: ACTION_TYPES.FETCH_SERVICEDESCRIPTION,
        payload: axios.get<IServiceDescription>(requestUrl),
    };
};

export const createEntity: ICrudPutAction<IServiceDescription> = (entity) => async (dispatch) => {
    const result = await dispatch({
        type: ACTION_TYPES.CREATE_SERVICEDESCRIPTION,
        payload: axios.post(apiUrl, cleanEntity(entity)),
    });
    return result;
};

export const updateEntity: ICrudPutAction<IServiceDescription> = (entity) => async (dispatch) => {
    const result = await dispatch({
        type: ACTION_TYPES.UPDATE_SERVICEDESCRIPTION,
        payload: axios.put(apiUrl, cleanEntity(entity)),
    });
    return result;
};

export const deleteEntity: ICrudDeleteAction<IServiceDescription> = (id) => async (dispatch) => {
    const requestUrl = `${apiUrl}/${id}`;
    const result = await dispatch({
        type: ACTION_TYPES.DELETE_SERVICEDESCRIPTION,
        payload: axios.delete(requestUrl),
    });
    return result;
};

export const reset = () => ({
    type: ACTION_TYPES.RESET,
});

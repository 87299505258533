import React from "react";
export function Step3() {
    return (
        <>
            <h2 style={{marginTop: "8px", marginBottom: "24px"}} className="title-custom-style">
                {`Notification automatique`}
            </h2>
            <p style={{marginBottom: "24px"}}>
                {`Email de notification client à la création/mise à jour d'un rendez-vous`}
            </p>
            <p style={{fontSize: "14px"}}>
                <i>{`Confirmation de rendez-vous, notification si changement  de date/heure.`}</i>
            </p>
        </>
    );
}

import React, {useState} from "react";
import {Button, Modal, ModalBody, ModalHeader, Progress} from "reactstrap";
import {onboardingService} from "./onboarding.service";
import {useSelector} from "react-redux";
import {IRootState} from "../../reducers";
import {NavLink as Link} from "react-router-dom";
import {SCORE_LIMIT} from "common/services";
import {HelpAction} from "../forms/help/HelpAction";
import {ModalContent, ModalContentWrapper} from "./ModalContent";

/**
 * Prénom
 * Nom
 * Forme juridique
 * Siège social
 *
 * Fonctionnalités -> obigatoire
 *
 * Il faut des prestations
 * Il faut des clients
 *  => on peut faire des rendez-vous
 *
 * SIRET
 * LOGO
 *
 *
 * @returns
 */
export function OnboardingProgress() {
    const [visible, setVisible] = useState(false);
    const account = useSelector(({authentication}: IRootState) => authentication.account);
    const platformUser = useSelector(({platformUser}: IRootState) => platformUser.entity);
    if (!account || !platformUser) {
        return null;
    }
    const score = onboardingService.readyNote(account, platformUser) * 10;
    if (score >= 100) {
        return null;
    }
    return (
        <>
            <div
                style={{paddingBottom: "12px", cursor: "pointer"}}
                onClick={() => setVisible(true)}
            >
                <p style={{textAlign: "center", marginBottom: "4px", fontWeight: "bold"}}>
                    Progression de la configuration
                </p>
                <Progress style={{height: "24px", fontWeight: "bold"}} value={score}>
                    {score}%
                </Progress>
            </div>
            {visible && (
                <Modal isOpen={true} id="onboarding-page" autoFocus={false}>
                    <div
                        style={{
                            height: "100%",
                            display: "grid",
                            gridTemplateRows: "min-content 1fr",
                        }}
                    >
                        <ModalBody style={{display: "flex"}}>
                            <ModalContentWrapper>
                                <ModalContent>
                                    <h2
                                        style={{marginTop: "8px", marginBottom: "16px"}}
                                        className="title-custom-style"
                                    >
                                        Progression de la configuration
                                    </h2>
                                    <p>Pour terminer la configuration, il vous reste à :</p>
                                    <ul>
                                        {account.stats.serviceDescriptions <= 0 && (
                                            <li>
                                                <Link to="/service-description">
                                                    Ajouter des prestations
                                                </Link>
                                            </li>
                                        )}
                                        {account.stats.customers <= 0 && (
                                            <li style={{marginTop: "8px"}}>
                                                <Link to="/customer">Ajouter des clients</Link>
                                            </li>
                                        )}
                                        {!platformUser.company ||
                                            !platformUser.companyType ||
                                            (!platformUser.siret && (
                                                <li style={{marginTop: "8px"}}>
                                                    <Link to="/account/invoice-settings">
                                                        {`Informations sur l'entreprise`}
                                                    </Link>
                                                </li>
                                            ))}
                                        {!platformUser.zip ||
                                            !platformUser.city ||
                                            (!platformUser.address && (
                                                <li style={{marginTop: "8px"}}>
                                                    <Link to="/account/invoice-settings">
                                                        {`Adresse de l'entreprise`}
                                                    </Link>
                                                </li>
                                            ))}
                                        {!platformUser.geocodeScore ||
                                            (parseFloat(platformUser.geocodeScore) <
                                                SCORE_LIMIT && (
                                                <li style={{marginTop: "8px"}}>
                                                    <Link to="/account/invoice-settings">
                                                        {`Adresse de l'entreprise imprécise`}
                                                    </Link>
                                                </li>
                                            ))}
                                        {!platformUser.logo && (
                                            <li style={{marginTop: "8px"}}>
                                                <Link to="/account/invoice-settings">
                                                    {`Renseignez le logo de l'entreprise`}
                                                </Link>
                                            </li>
                                        )}
                                    </ul>
                                </ModalContent>
                                <Button
                                    id="closeButton"
                                    type="button"
                                    color="success"
                                    style={{marginTop: "14px"}}
                                    onClick={() => setVisible(false)}
                                >
                                    <span>Fermer</span>
                                </Button>
                            </ModalContentWrapper>
                        </ModalBody>
                    </div>
                </Modal>
            )}
        </>
    );
}

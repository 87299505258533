import {Numberable} from "./types";

function floatValue(value: Numberable | null | undefined): number {
    let result = 0;
    if (typeof value === "number" && !isNaN(value)) {
        result = value;
    } else if (typeof value === "string") {
        result = parseFloat(value);
    }
    return result;
}

function normalize(value: Numberable | null | undefined): number {
    const result = floatValue(value);
    return parseFloat(result.toFixed(2));
}

export const numberService = {
    floatValue,
    normalize,
};
// TODO make a service here

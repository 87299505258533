import * as Sentry from "@sentry/react";
import {IRootState} from "app/reducers";
import React from "react";
import {Trans as Translate} from "react-i18next";
import {useSelector} from "react-redux";
import {Redirect, Route, RouteProps} from "react-router-dom";
interface IPrivateRouteProps extends RouteProps {
    hasAnyAuthorities?: string[];
}

const PrivateRouteComponent = ({
    component: Component,
    hasAnyAuthorities = [],
    ...rest
}: IPrivateRouteProps) => {
    const sessionHasBeenFetched = useSelector(
        ({authentication}: IRootState) => authentication.sessionHasBeenFetched
    );
    const isAuthenticated = useSelector(
        ({authentication}: IRootState) => authentication.isAuthenticated
    );
    const isAuthorized = useSelector(({authentication}: IRootState) =>
        hasAnyAuthority(authentication.account?.authorities ?? [], hasAnyAuthorities)
    );

    const renderRedirect = (props) => {
        if (!sessionHasBeenFetched) {
            return <div></div>;
        }
        if (!isAuthenticated) {
            return (
                <Redirect
                    to={{
                        pathname: "/login",
                        search: props.location.search,
                        state: {from: props.location},
                    }}
                />
            );
        }
        if (!isAuthorized) {
            <div className="insufficient-authority">
                <div className="alert alert-danger">
                    <Translate i18nKey="error.http.403">
                        You are not authorized to access this page.
                    </Translate>
                </div>
            </div>;
        }
        return (
            <Sentry.ErrorBoundary>{Component && <Component {...props} />}</Sentry.ErrorBoundary>
        );
    };

    if (!Component)
        throw new Error(
            `A component needs to be specified for private route for path ${(rest as any).path}`
        );

    return <Route {...rest} render={renderRedirect} />;
};

const hasAnyAuthority = (authorities: string[], hasAnyAuthorities: string[]) => {
    if (authorities && authorities.length !== 0) {
        if (hasAnyAuthorities.length === 0) {
            return true;
        }
        return hasAnyAuthorities.some((auth) => authorities.includes(auth));
    }
    return false;
};

export default PrivateRouteComponent;

import {IAccount, IPlatformUser} from "common/types";
import React from "react";
import {Modal, ModalBody} from "reactstrap";
import {OnBoardingStatus, ignoreOnboarding} from "../../reducers/user/onboarding";

import {AccountFeatures} from "./steps/account-features/AccountFeatures";
import {AccountFeaturesGoogleAgenda} from "./steps/account-features/AccountFeaturesGoogleAgenda";

import {useDispatch} from "react-redux";
import {EndingAssistant} from "./steps/ending-assistant/EndingAssistant";
import {HelpTour} from "./steps/help-tour/HelpTour";

type Props = {account: IAccount; platformUser: IPlatformUser; status: OnBoardingStatus};

export function Onboarding({account, platformUser, status}: Props) {
    const dispatch = useDispatch();
    if (status === "FINISHED") {
        return null;
    }
    let content: React.ReactNode;
    if (status === "FEATURES") {
        content = <AccountFeaturesGoogleAgenda platformUser={platformUser} account={account} />;
    } else if (status === "FEATURES2") {
        content = <AccountFeatures platformUser={platformUser} account={account} />;
    } else if (status === "ENDING") {
        content = <EndingAssistant />;
    } else {
        content = <HelpTour />;
    }
    return (
        <Modal isOpen={true} id="onboarding-page" autoFocus={false} toggle={handleClose}>
            <div
                style={{
                    display: "grid",
                    gridTemplateRows: "min-content",
                }}
            >
                <ModalBody style={{display: "flex"}}>{content}</ModalBody>
            </div>
        </Modal>
    );

    function handleClose() {
        dispatch(ignoreOnboarding());
    }
}

import {IAccount, IPlatformUser} from "common/types";
import {SCORE_LIMIT} from "common/services";

function readyToCreateEvent(account: IAccount) {
    return account.stats.customers > 0;
}

function readyToCreateService(account: IAccount) {
    return account.stats.serviceDescriptions > 0;
}

function readyToInvoice(account: IAccount, platformUser: IPlatformUser) {
    return (
        readyToCreateEvent(account) &&
        readyToCreateService(account) &&
        platformUser.user?.firstName &&
        platformUser.user?.lastName &&
        platformUser.company &&
        platformUser.companyType &&
        platformUser.siret &&
        platformUser.zip &&
        platformUser.city &&
        platformUser.address
    );
}

function readyNote(account: IAccount, platformUser: IPlatformUser) {
    let note = 0;
    if (account.stats.customers > 0) {
        note += 2;
    }
    if (account.stats.serviceDescriptions > 0) {
        note += 2;
    }
    if (platformUser.user?.firstName && platformUser.user?.lastName) {
        note += 1;
    }
    if (platformUser.company && platformUser.companyType && platformUser.siret) {
        note += 2;
    }
    if (platformUser.zip && platformUser.city && platformUser.address) {
        note += 1;
    }
    if (platformUser.geocodeScore && parseFloat(platformUser.geocodeScore) > SCORE_LIMIT) {
        note += 1;
    }
    if (platformUser.logo) {
        note += 1;
    }
    return note;
}

export const onboardingService = {
    readyToCreateEvent,
    readyToCreateService,
    readyToInvoice,
    readyNote,
};
